export default {
  namespaced: true,
  state: {
    pageElements: {
      anasayfa: [],
      tanimlamalar: [{
        title: 'Öğrenci',
        route: 'ogrenci',
        icon: 'HomeIcon',
      },
      {
        title: 'Stok',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Cari',
        route: 'anasayfa',
        icon: 'HomeIcon',
      }, {
        title: 'Kasa',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Depo',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Fiyat Tanımlamaları',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      ],
      hareketler: [{
        title: 'Alış',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Satış',
        route: 'hizlisatis',
        icon: 'FileIcon',
      },
      {
        title: 'Sipariş',
        route: 'hizlisatis',
        icon: 'FileIcon',
      },
      {
        title: 'Tahsilat',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Ödeme',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Depo Transferi',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      }],
      yemekhane: [{
        title: 'Dönem Tanımlama',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Menü Tanımlama',
        route: 'hizlisatis',
        icon: 'FileIcon',
      },
      {
        title: 'Abone İşlemleri',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Yemekhane Hareketleri',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      }],
      operasyonlar: [{
        title: 'Reçete',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Üretim',
        route: 'hizlisatis',
        icon: 'FileIcon',
      },
      {
        title: 'Sayım',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'İskonto',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Cihazlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      }],
      'yoklama-sistemi': [{
        title: 'Ayarlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Parametreler',
        route: 'hizlisatis',
        icon: 'FileIcon',
      }, {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      ],
      store: [{
        title: 'Ürün Tanımlama',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Barkod - Etiket Tanımlama',
        route: 'hizlisatis',
        icon: 'FileIcon',
      }],
      kutuphane: [{
        title: 'Kitap Tanımlama',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Hareketler',
        route: 'hizlisatis',
        icon: 'FileIcon',
      },
      {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      }],
      'veli-paneli': [{
        title: 'Liste',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Mutabakat',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Bilgilendirme',
        route: 'anasayfa',
        icon: 'HomeIcon',
      },
      {
        title: 'Raporlar',
        route: 'anasayfa',
        icon: 'HomeIcon',
      }],
    },
  },
  getters: {},
  mutations: {

  },
  actions: {},
}
