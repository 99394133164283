export default {
  namespaced: true,
  state: {
    tokenPrefix: '',
    userData: {},
  },
  getters: {},
  mutations: {
    SET_TOKEN_PREFIX(state, payload) {
      state.tokenPrefix = payload
    },
    UPDATE_USER_DATA(state, payload) {
      state.userData = payload
    },
  },
  actions: {},
}
