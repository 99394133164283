import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import menust from './menust'
import userInfo from './userInfo'
import kantin from './kantin'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    menust,
    userInfo,
    kantin,
  },
  strict: process.env.DEV,
})
