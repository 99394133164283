import proto from './kantinim_pb2'

export default {
  '/api/auth/login/': proto.loginAndAuth,
  '/api/auth/refresht/': proto.loginAndAuth,
  '/api/auth/verify/': proto.loginAndAuth,
  '/api/kullanici/': proto.loginAndAuth,
  '/api/ogrenci/': proto.ogrenciQuery,
  '/api/stok/': proto.stok,
  '/api/sepet/':proto.sepet,
  '/api/grup/':proto.grup,
  '/api/pdksgrup/':proto.pdksGrup
}
