/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.loginAndAuth = (function() {

    /**
     * Properties of a loginAndAuth.
     * @exports IloginAndAuth
     * @interface IloginAndAuth
     * @property {string|null} [username] loginAndAuth username
     * @property {string|null} [password] loginAndAuth password
     * @property {string|null} [access] loginAndAuth access
     * @property {string|null} [refresh] loginAndAuth refresh
     * @property {string|null} [prefix] loginAndAuth prefix
     * @property {string|null} [token] loginAndAuth token
     * @property {number|null} [type] loginAndAuth type
     * @property {string|null} [ytkId] loginAndAuth ytkId
     * @property {string|null} [ytkOkulId] loginAndAuth ytkOkulId
     * @property {string|null} [ytkKId] loginAndAuth ytkKId
     * @property {boolean|null} [ytkTanimlamalarogrenci] loginAndAuth ytkTanimlamalarogrenci
     * @property {boolean|null} [ytkTanimlamalarstok] loginAndAuth ytkTanimlamalarstok
     * @property {boolean|null} [ytkTanimlamalarcari] loginAndAuth ytkTanimlamalarcari
     * @property {boolean|null} [ytkTanimlamalarkasa] loginAndAuth ytkTanimlamalarkasa
     * @property {boolean|null} [ytkTanimlamalardepo] loginAndAuth ytkTanimlamalardepo
     * @property {boolean|null} [ytkTanimlamalarfiyattanimlamalari] loginAndAuth ytkTanimlamalarfiyattanimlamalari
     * @property {boolean|null} [ytkTanimlamalarraporlar] loginAndAuth ytkTanimlamalarraporlar
     * @property {boolean|null} [ytkHareketleralis] loginAndAuth ytkHareketleralis
     * @property {boolean|null} [ytkHareketlersatis] loginAndAuth ytkHareketlersatis
     * @property {boolean|null} [ytkHareketlersiparis] loginAndAuth ytkHareketlersiparis
     * @property {boolean|null} [ytkHareketlertahsilat] loginAndAuth ytkHareketlertahsilat
     * @property {boolean|null} [ytkHareketlerodeme] loginAndAuth ytkHareketlerodeme
     * @property {boolean|null} [ytkHareketlerdepotransferi] loginAndAuth ytkHareketlerdepotransferi
     * @property {boolean|null} [ytkHareketlerraporlar] loginAndAuth ytkHareketlerraporlar
     * @property {boolean|null} [ytkYemekhanedonemtanimlama] loginAndAuth ytkYemekhanedonemtanimlama
     * @property {boolean|null} [ytkYemekhanemenutanimlama] loginAndAuth ytkYemekhanemenutanimlama
     * @property {boolean|null} [ytkYemekhaneaboneislemleri] loginAndAuth ytkYemekhaneaboneislemleri
     * @property {boolean|null} [ytkYemekhanehareketleri] loginAndAuth ytkYemekhanehareketleri
     * @property {boolean|null} [ytkYemekhaneraporlar] loginAndAuth ytkYemekhaneraporlar
     * @property {boolean|null} [ytkOperasyonlarrecete] loginAndAuth ytkOperasyonlarrecete
     * @property {boolean|null} [ytkOperasyonlaruretim] loginAndAuth ytkOperasyonlaruretim
     * @property {boolean|null} [ytkOperasyonlarsayim] loginAndAuth ytkOperasyonlarsayim
     * @property {boolean|null} [ytkOperasyonlariskonto] loginAndAuth ytkOperasyonlariskonto
     * @property {boolean|null} [ytkOperasyonlarcihazlar] loginAndAuth ytkOperasyonlarcihazlar
     * @property {boolean|null} [ytkOperasyonlarraporlar] loginAndAuth ytkOperasyonlarraporlar
     * @property {boolean|null} [ytkYoklamaSistemiayarlar] loginAndAuth ytkYoklamaSistemiayarlar
     * @property {boolean|null} [ytkYoklamaSistemiparametreler] loginAndAuth ytkYoklamaSistemiparametreler
     * @property {boolean|null} [ytkYoklamaSistemiraporlar] loginAndAuth ytkYoklamaSistemiraporlar
     * @property {boolean|null} [ytkStoreuruntanimlama] loginAndAuth ytkStoreuruntanimlama
     * @property {boolean|null} [ytkStorebarkodetikettanimlama] loginAndAuth ytkStorebarkodetikettanimlama
     * @property {boolean|null} [ytkKutuphanekitaptanimlama] loginAndAuth ytkKutuphanekitaptanimlama
     * @property {boolean|null} [ytkKutuphanehareketler] loginAndAuth ytkKutuphanehareketler
     * @property {boolean|null} [ytkKutuphaneraporlar] loginAndAuth ytkKutuphaneraporlar
     * @property {boolean|null} [ytkVeliPaneliliste] loginAndAuth ytkVeliPaneliliste
     * @property {boolean|null} [ytkVeliPanelimutabakat] loginAndAuth ytkVeliPanelimutabakat
     * @property {boolean|null} [ytkVeliPanelibilgilendirme] loginAndAuth ytkVeliPanelibilgilendirme
     * @property {boolean|null} [ytkVeliPaneliraporlar] loginAndAuth ytkVeliPaneliraporlar
     * @property {boolean|null} [ytkYonetici] loginAndAuth ytkYonetici
     * @property {string|null} [kId] loginAndAuth kId
     * @property {string|null} [kOkulId] loginAndAuth kOkulId
     * @property {string|null} [kAdiSoyadi] loginAndAuth kAdiSoyadi
     * @property {string|null} [kSifre] loginAndAuth kSifre
     * @property {string|null} [kTel] loginAndAuth kTel
     * @property {string|null} [kMail] loginAndAuth kMail
     * @property {string|null} [kSonGiris] loginAndAuth kSonGiris
     * @property {string|null} [kKayitTarihi] loginAndAuth kKayitTarihi
     * @property {string|null} [kTckn] loginAndAuth kTckn
     * @property {string|null} [kKAdi] loginAndAuth kKAdi
     * @property {string|null} [kTasarim] loginAndAuth kTasarim
     * @property {number|null} [tip] loginAndAuth tip
     */

    /**
     * Constructs a new loginAndAuth.
     * @exports loginAndAuth
     * @classdesc Represents a loginAndAuth.
     * @implements IloginAndAuth
     * @constructor
     * @param {IloginAndAuth=} [properties] Properties to set
     */
    function loginAndAuth(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * loginAndAuth username.
     * @member {string} username
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.username = "";

    /**
     * loginAndAuth password.
     * @member {string} password
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.password = "";

    /**
     * loginAndAuth access.
     * @member {string} access
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.access = "";

    /**
     * loginAndAuth refresh.
     * @member {string} refresh
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.refresh = "";

    /**
     * loginAndAuth prefix.
     * @member {string} prefix
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.prefix = "";

    /**
     * loginAndAuth token.
     * @member {string} token
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.token = "";

    /**
     * loginAndAuth type.
     * @member {number} type
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.type = 0;

    /**
     * loginAndAuth ytkId.
     * @member {string} ytkId
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkId = "";

    /**
     * loginAndAuth ytkOkulId.
     * @member {string} ytkOkulId
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOkulId = "";

    /**
     * loginAndAuth ytkKId.
     * @member {string} ytkKId
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkKId = "";

    /**
     * loginAndAuth ytkTanimlamalarogrenci.
     * @member {boolean} ytkTanimlamalarogrenci
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalarogrenci = false;

    /**
     * loginAndAuth ytkTanimlamalarstok.
     * @member {boolean} ytkTanimlamalarstok
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalarstok = false;

    /**
     * loginAndAuth ytkTanimlamalarcari.
     * @member {boolean} ytkTanimlamalarcari
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalarcari = false;

    /**
     * loginAndAuth ytkTanimlamalarkasa.
     * @member {boolean} ytkTanimlamalarkasa
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalarkasa = false;

    /**
     * loginAndAuth ytkTanimlamalardepo.
     * @member {boolean} ytkTanimlamalardepo
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalardepo = false;

    /**
     * loginAndAuth ytkTanimlamalarfiyattanimlamalari.
     * @member {boolean} ytkTanimlamalarfiyattanimlamalari
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalarfiyattanimlamalari = false;

    /**
     * loginAndAuth ytkTanimlamalarraporlar.
     * @member {boolean} ytkTanimlamalarraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkTanimlamalarraporlar = false;

    /**
     * loginAndAuth ytkHareketleralis.
     * @member {boolean} ytkHareketleralis
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketleralis = false;

    /**
     * loginAndAuth ytkHareketlersatis.
     * @member {boolean} ytkHareketlersatis
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketlersatis = false;

    /**
     * loginAndAuth ytkHareketlersiparis.
     * @member {boolean} ytkHareketlersiparis
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketlersiparis = false;

    /**
     * loginAndAuth ytkHareketlertahsilat.
     * @member {boolean} ytkHareketlertahsilat
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketlertahsilat = false;

    /**
     * loginAndAuth ytkHareketlerodeme.
     * @member {boolean} ytkHareketlerodeme
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketlerodeme = false;

    /**
     * loginAndAuth ytkHareketlerdepotransferi.
     * @member {boolean} ytkHareketlerdepotransferi
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketlerdepotransferi = false;

    /**
     * loginAndAuth ytkHareketlerraporlar.
     * @member {boolean} ytkHareketlerraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkHareketlerraporlar = false;

    /**
     * loginAndAuth ytkYemekhanedonemtanimlama.
     * @member {boolean} ytkYemekhanedonemtanimlama
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYemekhanedonemtanimlama = false;

    /**
     * loginAndAuth ytkYemekhanemenutanimlama.
     * @member {boolean} ytkYemekhanemenutanimlama
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYemekhanemenutanimlama = false;

    /**
     * loginAndAuth ytkYemekhaneaboneislemleri.
     * @member {boolean} ytkYemekhaneaboneislemleri
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYemekhaneaboneislemleri = false;

    /**
     * loginAndAuth ytkYemekhanehareketleri.
     * @member {boolean} ytkYemekhanehareketleri
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYemekhanehareketleri = false;

    /**
     * loginAndAuth ytkYemekhaneraporlar.
     * @member {boolean} ytkYemekhaneraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYemekhaneraporlar = false;

    /**
     * loginAndAuth ytkOperasyonlarrecete.
     * @member {boolean} ytkOperasyonlarrecete
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOperasyonlarrecete = false;

    /**
     * loginAndAuth ytkOperasyonlaruretim.
     * @member {boolean} ytkOperasyonlaruretim
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOperasyonlaruretim = false;

    /**
     * loginAndAuth ytkOperasyonlarsayim.
     * @member {boolean} ytkOperasyonlarsayim
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOperasyonlarsayim = false;

    /**
     * loginAndAuth ytkOperasyonlariskonto.
     * @member {boolean} ytkOperasyonlariskonto
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOperasyonlariskonto = false;

    /**
     * loginAndAuth ytkOperasyonlarcihazlar.
     * @member {boolean} ytkOperasyonlarcihazlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOperasyonlarcihazlar = false;

    /**
     * loginAndAuth ytkOperasyonlarraporlar.
     * @member {boolean} ytkOperasyonlarraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkOperasyonlarraporlar = false;

    /**
     * loginAndAuth ytkYoklamaSistemiayarlar.
     * @member {boolean} ytkYoklamaSistemiayarlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYoklamaSistemiayarlar = false;

    /**
     * loginAndAuth ytkYoklamaSistemiparametreler.
     * @member {boolean} ytkYoklamaSistemiparametreler
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYoklamaSistemiparametreler = false;

    /**
     * loginAndAuth ytkYoklamaSistemiraporlar.
     * @member {boolean} ytkYoklamaSistemiraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYoklamaSistemiraporlar = false;

    /**
     * loginAndAuth ytkStoreuruntanimlama.
     * @member {boolean} ytkStoreuruntanimlama
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkStoreuruntanimlama = false;

    /**
     * loginAndAuth ytkStorebarkodetikettanimlama.
     * @member {boolean} ytkStorebarkodetikettanimlama
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkStorebarkodetikettanimlama = false;

    /**
     * loginAndAuth ytkKutuphanekitaptanimlama.
     * @member {boolean} ytkKutuphanekitaptanimlama
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkKutuphanekitaptanimlama = false;

    /**
     * loginAndAuth ytkKutuphanehareketler.
     * @member {boolean} ytkKutuphanehareketler
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkKutuphanehareketler = false;

    /**
     * loginAndAuth ytkKutuphaneraporlar.
     * @member {boolean} ytkKutuphaneraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkKutuphaneraporlar = false;

    /**
     * loginAndAuth ytkVeliPaneliliste.
     * @member {boolean} ytkVeliPaneliliste
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkVeliPaneliliste = false;

    /**
     * loginAndAuth ytkVeliPanelimutabakat.
     * @member {boolean} ytkVeliPanelimutabakat
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkVeliPanelimutabakat = false;

    /**
     * loginAndAuth ytkVeliPanelibilgilendirme.
     * @member {boolean} ytkVeliPanelibilgilendirme
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkVeliPanelibilgilendirme = false;

    /**
     * loginAndAuth ytkVeliPaneliraporlar.
     * @member {boolean} ytkVeliPaneliraporlar
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkVeliPaneliraporlar = false;

    /**
     * loginAndAuth ytkYonetici.
     * @member {boolean} ytkYonetici
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.ytkYonetici = false;

    /**
     * loginAndAuth kId.
     * @member {string} kId
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kId = "";

    /**
     * loginAndAuth kOkulId.
     * @member {string} kOkulId
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kOkulId = "";

    /**
     * loginAndAuth kAdiSoyadi.
     * @member {string} kAdiSoyadi
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kAdiSoyadi = "";

    /**
     * loginAndAuth kSifre.
     * @member {string} kSifre
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kSifre = "";

    /**
     * loginAndAuth kTel.
     * @member {string} kTel
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kTel = "";

    /**
     * loginAndAuth kMail.
     * @member {string} kMail
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kMail = "";

    /**
     * loginAndAuth kSonGiris.
     * @member {string} kSonGiris
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kSonGiris = "";

    /**
     * loginAndAuth kKayitTarihi.
     * @member {string} kKayitTarihi
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kKayitTarihi = "";

    /**
     * loginAndAuth kTckn.
     * @member {string} kTckn
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kTckn = "";

    /**
     * loginAndAuth kKAdi.
     * @member {string} kKAdi
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kKAdi = "";

    /**
     * loginAndAuth kTasarim.
     * @member {string} kTasarim
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.kTasarim = "";

    /**
     * loginAndAuth tip.
     * @member {number} tip
     * @memberof loginAndAuth
     * @instance
     */
    loginAndAuth.prototype.tip = 0;

    /**
     * Creates a new loginAndAuth instance using the specified properties.
     * @function create
     * @memberof loginAndAuth
     * @static
     * @param {IloginAndAuth=} [properties] Properties to set
     * @returns {loginAndAuth} loginAndAuth instance
     */
    loginAndAuth.create = function create(properties) {
        return new loginAndAuth(properties);
    };

    /**
     * Encodes the specified loginAndAuth message. Does not implicitly {@link loginAndAuth.verify|verify} messages.
     * @function encode
     * @memberof loginAndAuth
     * @static
     * @param {IloginAndAuth} message loginAndAuth message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    loginAndAuth.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.username != null && Object.hasOwnProperty.call(message, "username"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.username);
        if (message.password != null && Object.hasOwnProperty.call(message, "password"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
        if (message.access != null && Object.hasOwnProperty.call(message, "access"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.access);
        if (message.refresh != null && Object.hasOwnProperty.call(message, "refresh"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.refresh);
        if (message.prefix != null && Object.hasOwnProperty.call(message, "prefix"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.prefix);
        if (message.token != null && Object.hasOwnProperty.call(message, "token"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.token);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.type);
        if (message.ytkId != null && Object.hasOwnProperty.call(message, "ytkId"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.ytkId);
        if (message.ytkOkulId != null && Object.hasOwnProperty.call(message, "ytkOkulId"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.ytkOkulId);
        if (message.ytkKId != null && Object.hasOwnProperty.call(message, "ytkKId"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.ytkKId);
        if (message.ytkTanimlamalarogrenci != null && Object.hasOwnProperty.call(message, "ytkTanimlamalarogrenci"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.ytkTanimlamalarogrenci);
        if (message.ytkTanimlamalarstok != null && Object.hasOwnProperty.call(message, "ytkTanimlamalarstok"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.ytkTanimlamalarstok);
        if (message.ytkTanimlamalarcari != null && Object.hasOwnProperty.call(message, "ytkTanimlamalarcari"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.ytkTanimlamalarcari);
        if (message.ytkTanimlamalarkasa != null && Object.hasOwnProperty.call(message, "ytkTanimlamalarkasa"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.ytkTanimlamalarkasa);
        if (message.ytkTanimlamalardepo != null && Object.hasOwnProperty.call(message, "ytkTanimlamalardepo"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.ytkTanimlamalardepo);
        if (message.ytkTanimlamalarfiyattanimlamalari != null && Object.hasOwnProperty.call(message, "ytkTanimlamalarfiyattanimlamalari"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.ytkTanimlamalarfiyattanimlamalari);
        if (message.ytkTanimlamalarraporlar != null && Object.hasOwnProperty.call(message, "ytkTanimlamalarraporlar"))
            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.ytkTanimlamalarraporlar);
        if (message.ytkHareketleralis != null && Object.hasOwnProperty.call(message, "ytkHareketleralis"))
            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.ytkHareketleralis);
        if (message.ytkHareketlersatis != null && Object.hasOwnProperty.call(message, "ytkHareketlersatis"))
            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.ytkHareketlersatis);
        if (message.ytkHareketlersiparis != null && Object.hasOwnProperty.call(message, "ytkHareketlersiparis"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.ytkHareketlersiparis);
        if (message.ytkHareketlertahsilat != null && Object.hasOwnProperty.call(message, "ytkHareketlertahsilat"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.ytkHareketlertahsilat);
        if (message.ytkHareketlerodeme != null && Object.hasOwnProperty.call(message, "ytkHareketlerodeme"))
            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.ytkHareketlerodeme);
        if (message.ytkHareketlerdepotransferi != null && Object.hasOwnProperty.call(message, "ytkHareketlerdepotransferi"))
            writer.uint32(/* id 23, wireType 0 =*/184).bool(message.ytkHareketlerdepotransferi);
        if (message.ytkHareketlerraporlar != null && Object.hasOwnProperty.call(message, "ytkHareketlerraporlar"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.ytkHareketlerraporlar);
        if (message.ytkYemekhanedonemtanimlama != null && Object.hasOwnProperty.call(message, "ytkYemekhanedonemtanimlama"))
            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.ytkYemekhanedonemtanimlama);
        if (message.ytkYemekhanemenutanimlama != null && Object.hasOwnProperty.call(message, "ytkYemekhanemenutanimlama"))
            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.ytkYemekhanemenutanimlama);
        if (message.ytkYemekhaneaboneislemleri != null && Object.hasOwnProperty.call(message, "ytkYemekhaneaboneislemleri"))
            writer.uint32(/* id 27, wireType 0 =*/216).bool(message.ytkYemekhaneaboneislemleri);
        if (message.ytkYemekhanehareketleri != null && Object.hasOwnProperty.call(message, "ytkYemekhanehareketleri"))
            writer.uint32(/* id 28, wireType 0 =*/224).bool(message.ytkYemekhanehareketleri);
        if (message.ytkYemekhaneraporlar != null && Object.hasOwnProperty.call(message, "ytkYemekhaneraporlar"))
            writer.uint32(/* id 29, wireType 0 =*/232).bool(message.ytkYemekhaneraporlar);
        if (message.ytkOperasyonlarrecete != null && Object.hasOwnProperty.call(message, "ytkOperasyonlarrecete"))
            writer.uint32(/* id 30, wireType 0 =*/240).bool(message.ytkOperasyonlarrecete);
        if (message.ytkOperasyonlaruretim != null && Object.hasOwnProperty.call(message, "ytkOperasyonlaruretim"))
            writer.uint32(/* id 31, wireType 0 =*/248).bool(message.ytkOperasyonlaruretim);
        if (message.ytkOperasyonlarsayim != null && Object.hasOwnProperty.call(message, "ytkOperasyonlarsayim"))
            writer.uint32(/* id 32, wireType 0 =*/256).bool(message.ytkOperasyonlarsayim);
        if (message.ytkOperasyonlariskonto != null && Object.hasOwnProperty.call(message, "ytkOperasyonlariskonto"))
            writer.uint32(/* id 33, wireType 0 =*/264).bool(message.ytkOperasyonlariskonto);
        if (message.ytkOperasyonlarcihazlar != null && Object.hasOwnProperty.call(message, "ytkOperasyonlarcihazlar"))
            writer.uint32(/* id 34, wireType 0 =*/272).bool(message.ytkOperasyonlarcihazlar);
        if (message.ytkOperasyonlarraporlar != null && Object.hasOwnProperty.call(message, "ytkOperasyonlarraporlar"))
            writer.uint32(/* id 35, wireType 0 =*/280).bool(message.ytkOperasyonlarraporlar);
        if (message.ytkYoklamaSistemiayarlar != null && Object.hasOwnProperty.call(message, "ytkYoklamaSistemiayarlar"))
            writer.uint32(/* id 36, wireType 0 =*/288).bool(message.ytkYoklamaSistemiayarlar);
        if (message.ytkYoklamaSistemiparametreler != null && Object.hasOwnProperty.call(message, "ytkYoklamaSistemiparametreler"))
            writer.uint32(/* id 37, wireType 0 =*/296).bool(message.ytkYoklamaSistemiparametreler);
        if (message.ytkYoklamaSistemiraporlar != null && Object.hasOwnProperty.call(message, "ytkYoklamaSistemiraporlar"))
            writer.uint32(/* id 38, wireType 0 =*/304).bool(message.ytkYoklamaSistemiraporlar);
        if (message.ytkStoreuruntanimlama != null && Object.hasOwnProperty.call(message, "ytkStoreuruntanimlama"))
            writer.uint32(/* id 39, wireType 0 =*/312).bool(message.ytkStoreuruntanimlama);
        if (message.ytkStorebarkodetikettanimlama != null && Object.hasOwnProperty.call(message, "ytkStorebarkodetikettanimlama"))
            writer.uint32(/* id 40, wireType 0 =*/320).bool(message.ytkStorebarkodetikettanimlama);
        if (message.ytkKutuphanekitaptanimlama != null && Object.hasOwnProperty.call(message, "ytkKutuphanekitaptanimlama"))
            writer.uint32(/* id 41, wireType 0 =*/328).bool(message.ytkKutuphanekitaptanimlama);
        if (message.ytkKutuphanehareketler != null && Object.hasOwnProperty.call(message, "ytkKutuphanehareketler"))
            writer.uint32(/* id 42, wireType 0 =*/336).bool(message.ytkKutuphanehareketler);
        if (message.ytkKutuphaneraporlar != null && Object.hasOwnProperty.call(message, "ytkKutuphaneraporlar"))
            writer.uint32(/* id 43, wireType 0 =*/344).bool(message.ytkKutuphaneraporlar);
        if (message.ytkVeliPaneliliste != null && Object.hasOwnProperty.call(message, "ytkVeliPaneliliste"))
            writer.uint32(/* id 44, wireType 0 =*/352).bool(message.ytkVeliPaneliliste);
        if (message.ytkVeliPanelimutabakat != null && Object.hasOwnProperty.call(message, "ytkVeliPanelimutabakat"))
            writer.uint32(/* id 45, wireType 0 =*/360).bool(message.ytkVeliPanelimutabakat);
        if (message.ytkVeliPanelibilgilendirme != null && Object.hasOwnProperty.call(message, "ytkVeliPanelibilgilendirme"))
            writer.uint32(/* id 46, wireType 0 =*/368).bool(message.ytkVeliPanelibilgilendirme);
        if (message.ytkVeliPaneliraporlar != null && Object.hasOwnProperty.call(message, "ytkVeliPaneliraporlar"))
            writer.uint32(/* id 47, wireType 0 =*/376).bool(message.ytkVeliPaneliraporlar);
        if (message.ytkYonetici != null && Object.hasOwnProperty.call(message, "ytkYonetici"))
            writer.uint32(/* id 48, wireType 0 =*/384).bool(message.ytkYonetici);
        if (message.kId != null && Object.hasOwnProperty.call(message, "kId"))
            writer.uint32(/* id 49, wireType 2 =*/394).string(message.kId);
        if (message.kOkulId != null && Object.hasOwnProperty.call(message, "kOkulId"))
            writer.uint32(/* id 50, wireType 2 =*/402).string(message.kOkulId);
        if (message.kAdiSoyadi != null && Object.hasOwnProperty.call(message, "kAdiSoyadi"))
            writer.uint32(/* id 51, wireType 2 =*/410).string(message.kAdiSoyadi);
        if (message.kSifre != null && Object.hasOwnProperty.call(message, "kSifre"))
            writer.uint32(/* id 52, wireType 2 =*/418).string(message.kSifre);
        if (message.kTel != null && Object.hasOwnProperty.call(message, "kTel"))
            writer.uint32(/* id 53, wireType 2 =*/426).string(message.kTel);
        if (message.kMail != null && Object.hasOwnProperty.call(message, "kMail"))
            writer.uint32(/* id 54, wireType 2 =*/434).string(message.kMail);
        if (message.kSonGiris != null && Object.hasOwnProperty.call(message, "kSonGiris"))
            writer.uint32(/* id 55, wireType 2 =*/442).string(message.kSonGiris);
        if (message.kKayitTarihi != null && Object.hasOwnProperty.call(message, "kKayitTarihi"))
            writer.uint32(/* id 56, wireType 2 =*/450).string(message.kKayitTarihi);
        if (message.kTckn != null && Object.hasOwnProperty.call(message, "kTckn"))
            writer.uint32(/* id 57, wireType 2 =*/458).string(message.kTckn);
        if (message.kKAdi != null && Object.hasOwnProperty.call(message, "kKAdi"))
            writer.uint32(/* id 58, wireType 2 =*/466).string(message.kKAdi);
        if (message.kTasarim != null && Object.hasOwnProperty.call(message, "kTasarim"))
            writer.uint32(/* id 59, wireType 2 =*/474).string(message.kTasarim);
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 60, wireType 0 =*/480).int32(message.tip);
        return writer;
    };

    /**
     * Encodes the specified loginAndAuth message, length delimited. Does not implicitly {@link loginAndAuth.verify|verify} messages.
     * @function encodeDelimited
     * @memberof loginAndAuth
     * @static
     * @param {IloginAndAuth} message loginAndAuth message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    loginAndAuth.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a loginAndAuth message from the specified reader or buffer.
     * @function decode
     * @memberof loginAndAuth
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {loginAndAuth} loginAndAuth
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    loginAndAuth.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.loginAndAuth();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.username = reader.string();
                break;
            case 2:
                message.password = reader.string();
                break;
            case 3:
                message.access = reader.string();
                break;
            case 4:
                message.refresh = reader.string();
                break;
            case 5:
                message.prefix = reader.string();
                break;
            case 6:
                message.token = reader.string();
                break;
            case 7:
                message.type = reader.int32();
                break;
            case 8:
                message.ytkId = reader.string();
                break;
            case 9:
                message.ytkOkulId = reader.string();
                break;
            case 10:
                message.ytkKId = reader.string();
                break;
            case 11:
                message.ytkTanimlamalarogrenci = reader.bool();
                break;
            case 12:
                message.ytkTanimlamalarstok = reader.bool();
                break;
            case 13:
                message.ytkTanimlamalarcari = reader.bool();
                break;
            case 14:
                message.ytkTanimlamalarkasa = reader.bool();
                break;
            case 15:
                message.ytkTanimlamalardepo = reader.bool();
                break;
            case 16:
                message.ytkTanimlamalarfiyattanimlamalari = reader.bool();
                break;
            case 17:
                message.ytkTanimlamalarraporlar = reader.bool();
                break;
            case 18:
                message.ytkHareketleralis = reader.bool();
                break;
            case 19:
                message.ytkHareketlersatis = reader.bool();
                break;
            case 20:
                message.ytkHareketlersiparis = reader.bool();
                break;
            case 21:
                message.ytkHareketlertahsilat = reader.bool();
                break;
            case 22:
                message.ytkHareketlerodeme = reader.bool();
                break;
            case 23:
                message.ytkHareketlerdepotransferi = reader.bool();
                break;
            case 24:
                message.ytkHareketlerraporlar = reader.bool();
                break;
            case 25:
                message.ytkYemekhanedonemtanimlama = reader.bool();
                break;
            case 26:
                message.ytkYemekhanemenutanimlama = reader.bool();
                break;
            case 27:
                message.ytkYemekhaneaboneislemleri = reader.bool();
                break;
            case 28:
                message.ytkYemekhanehareketleri = reader.bool();
                break;
            case 29:
                message.ytkYemekhaneraporlar = reader.bool();
                break;
            case 30:
                message.ytkOperasyonlarrecete = reader.bool();
                break;
            case 31:
                message.ytkOperasyonlaruretim = reader.bool();
                break;
            case 32:
                message.ytkOperasyonlarsayim = reader.bool();
                break;
            case 33:
                message.ytkOperasyonlariskonto = reader.bool();
                break;
            case 34:
                message.ytkOperasyonlarcihazlar = reader.bool();
                break;
            case 35:
                message.ytkOperasyonlarraporlar = reader.bool();
                break;
            case 36:
                message.ytkYoklamaSistemiayarlar = reader.bool();
                break;
            case 37:
                message.ytkYoklamaSistemiparametreler = reader.bool();
                break;
            case 38:
                message.ytkYoklamaSistemiraporlar = reader.bool();
                break;
            case 39:
                message.ytkStoreuruntanimlama = reader.bool();
                break;
            case 40:
                message.ytkStorebarkodetikettanimlama = reader.bool();
                break;
            case 41:
                message.ytkKutuphanekitaptanimlama = reader.bool();
                break;
            case 42:
                message.ytkKutuphanehareketler = reader.bool();
                break;
            case 43:
                message.ytkKutuphaneraporlar = reader.bool();
                break;
            case 44:
                message.ytkVeliPaneliliste = reader.bool();
                break;
            case 45:
                message.ytkVeliPanelimutabakat = reader.bool();
                break;
            case 46:
                message.ytkVeliPanelibilgilendirme = reader.bool();
                break;
            case 47:
                message.ytkVeliPaneliraporlar = reader.bool();
                break;
            case 48:
                message.ytkYonetici = reader.bool();
                break;
            case 49:
                message.kId = reader.string();
                break;
            case 50:
                message.kOkulId = reader.string();
                break;
            case 51:
                message.kAdiSoyadi = reader.string();
                break;
            case 52:
                message.kSifre = reader.string();
                break;
            case 53:
                message.kTel = reader.string();
                break;
            case 54:
                message.kMail = reader.string();
                break;
            case 55:
                message.kSonGiris = reader.string();
                break;
            case 56:
                message.kKayitTarihi = reader.string();
                break;
            case 57:
                message.kTckn = reader.string();
                break;
            case 58:
                message.kKAdi = reader.string();
                break;
            case 59:
                message.kTasarim = reader.string();
                break;
            case 60:
                message.tip = reader.int32();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a loginAndAuth message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof loginAndAuth
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {loginAndAuth} loginAndAuth
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    loginAndAuth.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a loginAndAuth message.
     * @function verify
     * @memberof loginAndAuth
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    loginAndAuth.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.username != null && message.hasOwnProperty("username"))
            if (!$util.isString(message.username))
                return "username: string expected";
        if (message.password != null && message.hasOwnProperty("password"))
            if (!$util.isString(message.password))
                return "password: string expected";
        if (message.access != null && message.hasOwnProperty("access"))
            if (!$util.isString(message.access))
                return "access: string expected";
        if (message.refresh != null && message.hasOwnProperty("refresh"))
            if (!$util.isString(message.refresh))
                return "refresh: string expected";
        if (message.prefix != null && message.hasOwnProperty("prefix"))
            if (!$util.isString(message.prefix))
                return "prefix: string expected";
        if (message.token != null && message.hasOwnProperty("token"))
            if (!$util.isString(message.token))
                return "token: string expected";
        if (message.type != null && message.hasOwnProperty("type"))
            if (!$util.isInteger(message.type))
                return "type: integer expected";
        if (message.ytkId != null && message.hasOwnProperty("ytkId"))
            if (!$util.isString(message.ytkId))
                return "ytkId: string expected";
        if (message.ytkOkulId != null && message.hasOwnProperty("ytkOkulId"))
            if (!$util.isString(message.ytkOkulId))
                return "ytkOkulId: string expected";
        if (message.ytkKId != null && message.hasOwnProperty("ytkKId"))
            if (!$util.isString(message.ytkKId))
                return "ytkKId: string expected";
        if (message.ytkTanimlamalarogrenci != null && message.hasOwnProperty("ytkTanimlamalarogrenci"))
            if (typeof message.ytkTanimlamalarogrenci !== "boolean")
                return "ytkTanimlamalarogrenci: boolean expected";
        if (message.ytkTanimlamalarstok != null && message.hasOwnProperty("ytkTanimlamalarstok"))
            if (typeof message.ytkTanimlamalarstok !== "boolean")
                return "ytkTanimlamalarstok: boolean expected";
        if (message.ytkTanimlamalarcari != null && message.hasOwnProperty("ytkTanimlamalarcari"))
            if (typeof message.ytkTanimlamalarcari !== "boolean")
                return "ytkTanimlamalarcari: boolean expected";
        if (message.ytkTanimlamalarkasa != null && message.hasOwnProperty("ytkTanimlamalarkasa"))
            if (typeof message.ytkTanimlamalarkasa !== "boolean")
                return "ytkTanimlamalarkasa: boolean expected";
        if (message.ytkTanimlamalardepo != null && message.hasOwnProperty("ytkTanimlamalardepo"))
            if (typeof message.ytkTanimlamalardepo !== "boolean")
                return "ytkTanimlamalardepo: boolean expected";
        if (message.ytkTanimlamalarfiyattanimlamalari != null && message.hasOwnProperty("ytkTanimlamalarfiyattanimlamalari"))
            if (typeof message.ytkTanimlamalarfiyattanimlamalari !== "boolean")
                return "ytkTanimlamalarfiyattanimlamalari: boolean expected";
        if (message.ytkTanimlamalarraporlar != null && message.hasOwnProperty("ytkTanimlamalarraporlar"))
            if (typeof message.ytkTanimlamalarraporlar !== "boolean")
                return "ytkTanimlamalarraporlar: boolean expected";
        if (message.ytkHareketleralis != null && message.hasOwnProperty("ytkHareketleralis"))
            if (typeof message.ytkHareketleralis !== "boolean")
                return "ytkHareketleralis: boolean expected";
        if (message.ytkHareketlersatis != null && message.hasOwnProperty("ytkHareketlersatis"))
            if (typeof message.ytkHareketlersatis !== "boolean")
                return "ytkHareketlersatis: boolean expected";
        if (message.ytkHareketlersiparis != null && message.hasOwnProperty("ytkHareketlersiparis"))
            if (typeof message.ytkHareketlersiparis !== "boolean")
                return "ytkHareketlersiparis: boolean expected";
        if (message.ytkHareketlertahsilat != null && message.hasOwnProperty("ytkHareketlertahsilat"))
            if (typeof message.ytkHareketlertahsilat !== "boolean")
                return "ytkHareketlertahsilat: boolean expected";
        if (message.ytkHareketlerodeme != null && message.hasOwnProperty("ytkHareketlerodeme"))
            if (typeof message.ytkHareketlerodeme !== "boolean")
                return "ytkHareketlerodeme: boolean expected";
        if (message.ytkHareketlerdepotransferi != null && message.hasOwnProperty("ytkHareketlerdepotransferi"))
            if (typeof message.ytkHareketlerdepotransferi !== "boolean")
                return "ytkHareketlerdepotransferi: boolean expected";
        if (message.ytkHareketlerraporlar != null && message.hasOwnProperty("ytkHareketlerraporlar"))
            if (typeof message.ytkHareketlerraporlar !== "boolean")
                return "ytkHareketlerraporlar: boolean expected";
        if (message.ytkYemekhanedonemtanimlama != null && message.hasOwnProperty("ytkYemekhanedonemtanimlama"))
            if (typeof message.ytkYemekhanedonemtanimlama !== "boolean")
                return "ytkYemekhanedonemtanimlama: boolean expected";
        if (message.ytkYemekhanemenutanimlama != null && message.hasOwnProperty("ytkYemekhanemenutanimlama"))
            if (typeof message.ytkYemekhanemenutanimlama !== "boolean")
                return "ytkYemekhanemenutanimlama: boolean expected";
        if (message.ytkYemekhaneaboneislemleri != null && message.hasOwnProperty("ytkYemekhaneaboneislemleri"))
            if (typeof message.ytkYemekhaneaboneislemleri !== "boolean")
                return "ytkYemekhaneaboneislemleri: boolean expected";
        if (message.ytkYemekhanehareketleri != null && message.hasOwnProperty("ytkYemekhanehareketleri"))
            if (typeof message.ytkYemekhanehareketleri !== "boolean")
                return "ytkYemekhanehareketleri: boolean expected";
        if (message.ytkYemekhaneraporlar != null && message.hasOwnProperty("ytkYemekhaneraporlar"))
            if (typeof message.ytkYemekhaneraporlar !== "boolean")
                return "ytkYemekhaneraporlar: boolean expected";
        if (message.ytkOperasyonlarrecete != null && message.hasOwnProperty("ytkOperasyonlarrecete"))
            if (typeof message.ytkOperasyonlarrecete !== "boolean")
                return "ytkOperasyonlarrecete: boolean expected";
        if (message.ytkOperasyonlaruretim != null && message.hasOwnProperty("ytkOperasyonlaruretim"))
            if (typeof message.ytkOperasyonlaruretim !== "boolean")
                return "ytkOperasyonlaruretim: boolean expected";
        if (message.ytkOperasyonlarsayim != null && message.hasOwnProperty("ytkOperasyonlarsayim"))
            if (typeof message.ytkOperasyonlarsayim !== "boolean")
                return "ytkOperasyonlarsayim: boolean expected";
        if (message.ytkOperasyonlariskonto != null && message.hasOwnProperty("ytkOperasyonlariskonto"))
            if (typeof message.ytkOperasyonlariskonto !== "boolean")
                return "ytkOperasyonlariskonto: boolean expected";
        if (message.ytkOperasyonlarcihazlar != null && message.hasOwnProperty("ytkOperasyonlarcihazlar"))
            if (typeof message.ytkOperasyonlarcihazlar !== "boolean")
                return "ytkOperasyonlarcihazlar: boolean expected";
        if (message.ytkOperasyonlarraporlar != null && message.hasOwnProperty("ytkOperasyonlarraporlar"))
            if (typeof message.ytkOperasyonlarraporlar !== "boolean")
                return "ytkOperasyonlarraporlar: boolean expected";
        if (message.ytkYoklamaSistemiayarlar != null && message.hasOwnProperty("ytkYoklamaSistemiayarlar"))
            if (typeof message.ytkYoklamaSistemiayarlar !== "boolean")
                return "ytkYoklamaSistemiayarlar: boolean expected";
        if (message.ytkYoklamaSistemiparametreler != null && message.hasOwnProperty("ytkYoklamaSistemiparametreler"))
            if (typeof message.ytkYoklamaSistemiparametreler !== "boolean")
                return "ytkYoklamaSistemiparametreler: boolean expected";
        if (message.ytkYoklamaSistemiraporlar != null && message.hasOwnProperty("ytkYoklamaSistemiraporlar"))
            if (typeof message.ytkYoklamaSistemiraporlar !== "boolean")
                return "ytkYoklamaSistemiraporlar: boolean expected";
        if (message.ytkStoreuruntanimlama != null && message.hasOwnProperty("ytkStoreuruntanimlama"))
            if (typeof message.ytkStoreuruntanimlama !== "boolean")
                return "ytkStoreuruntanimlama: boolean expected";
        if (message.ytkStorebarkodetikettanimlama != null && message.hasOwnProperty("ytkStorebarkodetikettanimlama"))
            if (typeof message.ytkStorebarkodetikettanimlama !== "boolean")
                return "ytkStorebarkodetikettanimlama: boolean expected";
        if (message.ytkKutuphanekitaptanimlama != null && message.hasOwnProperty("ytkKutuphanekitaptanimlama"))
            if (typeof message.ytkKutuphanekitaptanimlama !== "boolean")
                return "ytkKutuphanekitaptanimlama: boolean expected";
        if (message.ytkKutuphanehareketler != null && message.hasOwnProperty("ytkKutuphanehareketler"))
            if (typeof message.ytkKutuphanehareketler !== "boolean")
                return "ytkKutuphanehareketler: boolean expected";
        if (message.ytkKutuphaneraporlar != null && message.hasOwnProperty("ytkKutuphaneraporlar"))
            if (typeof message.ytkKutuphaneraporlar !== "boolean")
                return "ytkKutuphaneraporlar: boolean expected";
        if (message.ytkVeliPaneliliste != null && message.hasOwnProperty("ytkVeliPaneliliste"))
            if (typeof message.ytkVeliPaneliliste !== "boolean")
                return "ytkVeliPaneliliste: boolean expected";
        if (message.ytkVeliPanelimutabakat != null && message.hasOwnProperty("ytkVeliPanelimutabakat"))
            if (typeof message.ytkVeliPanelimutabakat !== "boolean")
                return "ytkVeliPanelimutabakat: boolean expected";
        if (message.ytkVeliPanelibilgilendirme != null && message.hasOwnProperty("ytkVeliPanelibilgilendirme"))
            if (typeof message.ytkVeliPanelibilgilendirme !== "boolean")
                return "ytkVeliPanelibilgilendirme: boolean expected";
        if (message.ytkVeliPaneliraporlar != null && message.hasOwnProperty("ytkVeliPaneliraporlar"))
            if (typeof message.ytkVeliPaneliraporlar !== "boolean")
                return "ytkVeliPaneliraporlar: boolean expected";
        if (message.ytkYonetici != null && message.hasOwnProperty("ytkYonetici"))
            if (typeof message.ytkYonetici !== "boolean")
                return "ytkYonetici: boolean expected";
        if (message.kId != null && message.hasOwnProperty("kId"))
            if (!$util.isString(message.kId))
                return "kId: string expected";
        if (message.kOkulId != null && message.hasOwnProperty("kOkulId"))
            if (!$util.isString(message.kOkulId))
                return "kOkulId: string expected";
        if (message.kAdiSoyadi != null && message.hasOwnProperty("kAdiSoyadi"))
            if (!$util.isString(message.kAdiSoyadi))
                return "kAdiSoyadi: string expected";
        if (message.kSifre != null && message.hasOwnProperty("kSifre"))
            if (!$util.isString(message.kSifre))
                return "kSifre: string expected";
        if (message.kTel != null && message.hasOwnProperty("kTel"))
            if (!$util.isString(message.kTel))
                return "kTel: string expected";
        if (message.kMail != null && message.hasOwnProperty("kMail"))
            if (!$util.isString(message.kMail))
                return "kMail: string expected";
        if (message.kSonGiris != null && message.hasOwnProperty("kSonGiris"))
            if (!$util.isString(message.kSonGiris))
                return "kSonGiris: string expected";
        if (message.kKayitTarihi != null && message.hasOwnProperty("kKayitTarihi"))
            if (!$util.isString(message.kKayitTarihi))
                return "kKayitTarihi: string expected";
        if (message.kTckn != null && message.hasOwnProperty("kTckn"))
            if (!$util.isString(message.kTckn))
                return "kTckn: string expected";
        if (message.kKAdi != null && message.hasOwnProperty("kKAdi"))
            if (!$util.isString(message.kKAdi))
                return "kKAdi: string expected";
        if (message.kTasarim != null && message.hasOwnProperty("kTasarim"))
            if (!$util.isString(message.kTasarim))
                return "kTasarim: string expected";
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        return null;
    };

    /**
     * Creates a loginAndAuth message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof loginAndAuth
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {loginAndAuth} loginAndAuth
     */
    loginAndAuth.fromObject = function fromObject(object) {
        if (object instanceof $root.loginAndAuth)
            return object;
        var message = new $root.loginAndAuth();
        if (object.username != null)
            message.username = String(object.username);
        if (object.password != null)
            message.password = String(object.password);
        if (object.access != null)
            message.access = String(object.access);
        if (object.refresh != null)
            message.refresh = String(object.refresh);
        if (object.prefix != null)
            message.prefix = String(object.prefix);
        if (object.token != null)
            message.token = String(object.token);
        if (object.type != null)
            message.type = object.type | 0;
        if (object.ytkId != null)
            message.ytkId = String(object.ytkId);
        if (object.ytkOkulId != null)
            message.ytkOkulId = String(object.ytkOkulId);
        if (object.ytkKId != null)
            message.ytkKId = String(object.ytkKId);
        if (object.ytkTanimlamalarogrenci != null)
            message.ytkTanimlamalarogrenci = Boolean(object.ytkTanimlamalarogrenci);
        if (object.ytkTanimlamalarstok != null)
            message.ytkTanimlamalarstok = Boolean(object.ytkTanimlamalarstok);
        if (object.ytkTanimlamalarcari != null)
            message.ytkTanimlamalarcari = Boolean(object.ytkTanimlamalarcari);
        if (object.ytkTanimlamalarkasa != null)
            message.ytkTanimlamalarkasa = Boolean(object.ytkTanimlamalarkasa);
        if (object.ytkTanimlamalardepo != null)
            message.ytkTanimlamalardepo = Boolean(object.ytkTanimlamalardepo);
        if (object.ytkTanimlamalarfiyattanimlamalari != null)
            message.ytkTanimlamalarfiyattanimlamalari = Boolean(object.ytkTanimlamalarfiyattanimlamalari);
        if (object.ytkTanimlamalarraporlar != null)
            message.ytkTanimlamalarraporlar = Boolean(object.ytkTanimlamalarraporlar);
        if (object.ytkHareketleralis != null)
            message.ytkHareketleralis = Boolean(object.ytkHareketleralis);
        if (object.ytkHareketlersatis != null)
            message.ytkHareketlersatis = Boolean(object.ytkHareketlersatis);
        if (object.ytkHareketlersiparis != null)
            message.ytkHareketlersiparis = Boolean(object.ytkHareketlersiparis);
        if (object.ytkHareketlertahsilat != null)
            message.ytkHareketlertahsilat = Boolean(object.ytkHareketlertahsilat);
        if (object.ytkHareketlerodeme != null)
            message.ytkHareketlerodeme = Boolean(object.ytkHareketlerodeme);
        if (object.ytkHareketlerdepotransferi != null)
            message.ytkHareketlerdepotransferi = Boolean(object.ytkHareketlerdepotransferi);
        if (object.ytkHareketlerraporlar != null)
            message.ytkHareketlerraporlar = Boolean(object.ytkHareketlerraporlar);
        if (object.ytkYemekhanedonemtanimlama != null)
            message.ytkYemekhanedonemtanimlama = Boolean(object.ytkYemekhanedonemtanimlama);
        if (object.ytkYemekhanemenutanimlama != null)
            message.ytkYemekhanemenutanimlama = Boolean(object.ytkYemekhanemenutanimlama);
        if (object.ytkYemekhaneaboneislemleri != null)
            message.ytkYemekhaneaboneislemleri = Boolean(object.ytkYemekhaneaboneislemleri);
        if (object.ytkYemekhanehareketleri != null)
            message.ytkYemekhanehareketleri = Boolean(object.ytkYemekhanehareketleri);
        if (object.ytkYemekhaneraporlar != null)
            message.ytkYemekhaneraporlar = Boolean(object.ytkYemekhaneraporlar);
        if (object.ytkOperasyonlarrecete != null)
            message.ytkOperasyonlarrecete = Boolean(object.ytkOperasyonlarrecete);
        if (object.ytkOperasyonlaruretim != null)
            message.ytkOperasyonlaruretim = Boolean(object.ytkOperasyonlaruretim);
        if (object.ytkOperasyonlarsayim != null)
            message.ytkOperasyonlarsayim = Boolean(object.ytkOperasyonlarsayim);
        if (object.ytkOperasyonlariskonto != null)
            message.ytkOperasyonlariskonto = Boolean(object.ytkOperasyonlariskonto);
        if (object.ytkOperasyonlarcihazlar != null)
            message.ytkOperasyonlarcihazlar = Boolean(object.ytkOperasyonlarcihazlar);
        if (object.ytkOperasyonlarraporlar != null)
            message.ytkOperasyonlarraporlar = Boolean(object.ytkOperasyonlarraporlar);
        if (object.ytkYoklamaSistemiayarlar != null)
            message.ytkYoklamaSistemiayarlar = Boolean(object.ytkYoklamaSistemiayarlar);
        if (object.ytkYoklamaSistemiparametreler != null)
            message.ytkYoklamaSistemiparametreler = Boolean(object.ytkYoklamaSistemiparametreler);
        if (object.ytkYoklamaSistemiraporlar != null)
            message.ytkYoklamaSistemiraporlar = Boolean(object.ytkYoklamaSistemiraporlar);
        if (object.ytkStoreuruntanimlama != null)
            message.ytkStoreuruntanimlama = Boolean(object.ytkStoreuruntanimlama);
        if (object.ytkStorebarkodetikettanimlama != null)
            message.ytkStorebarkodetikettanimlama = Boolean(object.ytkStorebarkodetikettanimlama);
        if (object.ytkKutuphanekitaptanimlama != null)
            message.ytkKutuphanekitaptanimlama = Boolean(object.ytkKutuphanekitaptanimlama);
        if (object.ytkKutuphanehareketler != null)
            message.ytkKutuphanehareketler = Boolean(object.ytkKutuphanehareketler);
        if (object.ytkKutuphaneraporlar != null)
            message.ytkKutuphaneraporlar = Boolean(object.ytkKutuphaneraporlar);
        if (object.ytkVeliPaneliliste != null)
            message.ytkVeliPaneliliste = Boolean(object.ytkVeliPaneliliste);
        if (object.ytkVeliPanelimutabakat != null)
            message.ytkVeliPanelimutabakat = Boolean(object.ytkVeliPanelimutabakat);
        if (object.ytkVeliPanelibilgilendirme != null)
            message.ytkVeliPanelibilgilendirme = Boolean(object.ytkVeliPanelibilgilendirme);
        if (object.ytkVeliPaneliraporlar != null)
            message.ytkVeliPaneliraporlar = Boolean(object.ytkVeliPaneliraporlar);
        if (object.ytkYonetici != null)
            message.ytkYonetici = Boolean(object.ytkYonetici);
        if (object.kId != null)
            message.kId = String(object.kId);
        if (object.kOkulId != null)
            message.kOkulId = String(object.kOkulId);
        if (object.kAdiSoyadi != null)
            message.kAdiSoyadi = String(object.kAdiSoyadi);
        if (object.kSifre != null)
            message.kSifre = String(object.kSifre);
        if (object.kTel != null)
            message.kTel = String(object.kTel);
        if (object.kMail != null)
            message.kMail = String(object.kMail);
        if (object.kSonGiris != null)
            message.kSonGiris = String(object.kSonGiris);
        if (object.kKayitTarihi != null)
            message.kKayitTarihi = String(object.kKayitTarihi);
        if (object.kTckn != null)
            message.kTckn = String(object.kTckn);
        if (object.kKAdi != null)
            message.kKAdi = String(object.kKAdi);
        if (object.kTasarim != null)
            message.kTasarim = String(object.kTasarim);
        if (object.tip != null)
            message.tip = object.tip | 0;
        return message;
    };

    /**
     * Creates a plain object from a loginAndAuth message. Also converts values to other types if specified.
     * @function toObject
     * @memberof loginAndAuth
     * @static
     * @param {loginAndAuth} message loginAndAuth
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    loginAndAuth.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.username = "";
            object.password = "";
            object.access = "";
            object.refresh = "";
            object.prefix = "";
            object.token = "";
            object.type = 0;
            object.ytkId = "";
            object.ytkOkulId = "";
            object.ytkKId = "";
            object.ytkTanimlamalarogrenci = false;
            object.ytkTanimlamalarstok = false;
            object.ytkTanimlamalarcari = false;
            object.ytkTanimlamalarkasa = false;
            object.ytkTanimlamalardepo = false;
            object.ytkTanimlamalarfiyattanimlamalari = false;
            object.ytkTanimlamalarraporlar = false;
            object.ytkHareketleralis = false;
            object.ytkHareketlersatis = false;
            object.ytkHareketlersiparis = false;
            object.ytkHareketlertahsilat = false;
            object.ytkHareketlerodeme = false;
            object.ytkHareketlerdepotransferi = false;
            object.ytkHareketlerraporlar = false;
            object.ytkYemekhanedonemtanimlama = false;
            object.ytkYemekhanemenutanimlama = false;
            object.ytkYemekhaneaboneislemleri = false;
            object.ytkYemekhanehareketleri = false;
            object.ytkYemekhaneraporlar = false;
            object.ytkOperasyonlarrecete = false;
            object.ytkOperasyonlaruretim = false;
            object.ytkOperasyonlarsayim = false;
            object.ytkOperasyonlariskonto = false;
            object.ytkOperasyonlarcihazlar = false;
            object.ytkOperasyonlarraporlar = false;
            object.ytkYoklamaSistemiayarlar = false;
            object.ytkYoklamaSistemiparametreler = false;
            object.ytkYoklamaSistemiraporlar = false;
            object.ytkStoreuruntanimlama = false;
            object.ytkStorebarkodetikettanimlama = false;
            object.ytkKutuphanekitaptanimlama = false;
            object.ytkKutuphanehareketler = false;
            object.ytkKutuphaneraporlar = false;
            object.ytkVeliPaneliliste = false;
            object.ytkVeliPanelimutabakat = false;
            object.ytkVeliPanelibilgilendirme = false;
            object.ytkVeliPaneliraporlar = false;
            object.ytkYonetici = false;
            object.kId = "";
            object.kOkulId = "";
            object.kAdiSoyadi = "";
            object.kSifre = "";
            object.kTel = "";
            object.kMail = "";
            object.kSonGiris = "";
            object.kKayitTarihi = "";
            object.kTckn = "";
            object.kKAdi = "";
            object.kTasarim = "";
            object.tip = 0;
        }
        if (message.username != null && message.hasOwnProperty("username"))
            object.username = message.username;
        if (message.password != null && message.hasOwnProperty("password"))
            object.password = message.password;
        if (message.access != null && message.hasOwnProperty("access"))
            object.access = message.access;
        if (message.refresh != null && message.hasOwnProperty("refresh"))
            object.refresh = message.refresh;
        if (message.prefix != null && message.hasOwnProperty("prefix"))
            object.prefix = message.prefix;
        if (message.token != null && message.hasOwnProperty("token"))
            object.token = message.token;
        if (message.type != null && message.hasOwnProperty("type"))
            object.type = message.type;
        if (message.ytkId != null && message.hasOwnProperty("ytkId"))
            object.ytkId = message.ytkId;
        if (message.ytkOkulId != null && message.hasOwnProperty("ytkOkulId"))
            object.ytkOkulId = message.ytkOkulId;
        if (message.ytkKId != null && message.hasOwnProperty("ytkKId"))
            object.ytkKId = message.ytkKId;
        if (message.ytkTanimlamalarogrenci != null && message.hasOwnProperty("ytkTanimlamalarogrenci"))
            object.ytkTanimlamalarogrenci = message.ytkTanimlamalarogrenci;
        if (message.ytkTanimlamalarstok != null && message.hasOwnProperty("ytkTanimlamalarstok"))
            object.ytkTanimlamalarstok = message.ytkTanimlamalarstok;
        if (message.ytkTanimlamalarcari != null && message.hasOwnProperty("ytkTanimlamalarcari"))
            object.ytkTanimlamalarcari = message.ytkTanimlamalarcari;
        if (message.ytkTanimlamalarkasa != null && message.hasOwnProperty("ytkTanimlamalarkasa"))
            object.ytkTanimlamalarkasa = message.ytkTanimlamalarkasa;
        if (message.ytkTanimlamalardepo != null && message.hasOwnProperty("ytkTanimlamalardepo"))
            object.ytkTanimlamalardepo = message.ytkTanimlamalardepo;
        if (message.ytkTanimlamalarfiyattanimlamalari != null && message.hasOwnProperty("ytkTanimlamalarfiyattanimlamalari"))
            object.ytkTanimlamalarfiyattanimlamalari = message.ytkTanimlamalarfiyattanimlamalari;
        if (message.ytkTanimlamalarraporlar != null && message.hasOwnProperty("ytkTanimlamalarraporlar"))
            object.ytkTanimlamalarraporlar = message.ytkTanimlamalarraporlar;
        if (message.ytkHareketleralis != null && message.hasOwnProperty("ytkHareketleralis"))
            object.ytkHareketleralis = message.ytkHareketleralis;
        if (message.ytkHareketlersatis != null && message.hasOwnProperty("ytkHareketlersatis"))
            object.ytkHareketlersatis = message.ytkHareketlersatis;
        if (message.ytkHareketlersiparis != null && message.hasOwnProperty("ytkHareketlersiparis"))
            object.ytkHareketlersiparis = message.ytkHareketlersiparis;
        if (message.ytkHareketlertahsilat != null && message.hasOwnProperty("ytkHareketlertahsilat"))
            object.ytkHareketlertahsilat = message.ytkHareketlertahsilat;
        if (message.ytkHareketlerodeme != null && message.hasOwnProperty("ytkHareketlerodeme"))
            object.ytkHareketlerodeme = message.ytkHareketlerodeme;
        if (message.ytkHareketlerdepotransferi != null && message.hasOwnProperty("ytkHareketlerdepotransferi"))
            object.ytkHareketlerdepotransferi = message.ytkHareketlerdepotransferi;
        if (message.ytkHareketlerraporlar != null && message.hasOwnProperty("ytkHareketlerraporlar"))
            object.ytkHareketlerraporlar = message.ytkHareketlerraporlar;
        if (message.ytkYemekhanedonemtanimlama != null && message.hasOwnProperty("ytkYemekhanedonemtanimlama"))
            object.ytkYemekhanedonemtanimlama = message.ytkYemekhanedonemtanimlama;
        if (message.ytkYemekhanemenutanimlama != null && message.hasOwnProperty("ytkYemekhanemenutanimlama"))
            object.ytkYemekhanemenutanimlama = message.ytkYemekhanemenutanimlama;
        if (message.ytkYemekhaneaboneislemleri != null && message.hasOwnProperty("ytkYemekhaneaboneislemleri"))
            object.ytkYemekhaneaboneislemleri = message.ytkYemekhaneaboneislemleri;
        if (message.ytkYemekhanehareketleri != null && message.hasOwnProperty("ytkYemekhanehareketleri"))
            object.ytkYemekhanehareketleri = message.ytkYemekhanehareketleri;
        if (message.ytkYemekhaneraporlar != null && message.hasOwnProperty("ytkYemekhaneraporlar"))
            object.ytkYemekhaneraporlar = message.ytkYemekhaneraporlar;
        if (message.ytkOperasyonlarrecete != null && message.hasOwnProperty("ytkOperasyonlarrecete"))
            object.ytkOperasyonlarrecete = message.ytkOperasyonlarrecete;
        if (message.ytkOperasyonlaruretim != null && message.hasOwnProperty("ytkOperasyonlaruretim"))
            object.ytkOperasyonlaruretim = message.ytkOperasyonlaruretim;
        if (message.ytkOperasyonlarsayim != null && message.hasOwnProperty("ytkOperasyonlarsayim"))
            object.ytkOperasyonlarsayim = message.ytkOperasyonlarsayim;
        if (message.ytkOperasyonlariskonto != null && message.hasOwnProperty("ytkOperasyonlariskonto"))
            object.ytkOperasyonlariskonto = message.ytkOperasyonlariskonto;
        if (message.ytkOperasyonlarcihazlar != null && message.hasOwnProperty("ytkOperasyonlarcihazlar"))
            object.ytkOperasyonlarcihazlar = message.ytkOperasyonlarcihazlar;
        if (message.ytkOperasyonlarraporlar != null && message.hasOwnProperty("ytkOperasyonlarraporlar"))
            object.ytkOperasyonlarraporlar = message.ytkOperasyonlarraporlar;
        if (message.ytkYoklamaSistemiayarlar != null && message.hasOwnProperty("ytkYoklamaSistemiayarlar"))
            object.ytkYoklamaSistemiayarlar = message.ytkYoklamaSistemiayarlar;
        if (message.ytkYoklamaSistemiparametreler != null && message.hasOwnProperty("ytkYoklamaSistemiparametreler"))
            object.ytkYoklamaSistemiparametreler = message.ytkYoklamaSistemiparametreler;
        if (message.ytkYoklamaSistemiraporlar != null && message.hasOwnProperty("ytkYoklamaSistemiraporlar"))
            object.ytkYoklamaSistemiraporlar = message.ytkYoklamaSistemiraporlar;
        if (message.ytkStoreuruntanimlama != null && message.hasOwnProperty("ytkStoreuruntanimlama"))
            object.ytkStoreuruntanimlama = message.ytkStoreuruntanimlama;
        if (message.ytkStorebarkodetikettanimlama != null && message.hasOwnProperty("ytkStorebarkodetikettanimlama"))
            object.ytkStorebarkodetikettanimlama = message.ytkStorebarkodetikettanimlama;
        if (message.ytkKutuphanekitaptanimlama != null && message.hasOwnProperty("ytkKutuphanekitaptanimlama"))
            object.ytkKutuphanekitaptanimlama = message.ytkKutuphanekitaptanimlama;
        if (message.ytkKutuphanehareketler != null && message.hasOwnProperty("ytkKutuphanehareketler"))
            object.ytkKutuphanehareketler = message.ytkKutuphanehareketler;
        if (message.ytkKutuphaneraporlar != null && message.hasOwnProperty("ytkKutuphaneraporlar"))
            object.ytkKutuphaneraporlar = message.ytkKutuphaneraporlar;
        if (message.ytkVeliPaneliliste != null && message.hasOwnProperty("ytkVeliPaneliliste"))
            object.ytkVeliPaneliliste = message.ytkVeliPaneliliste;
        if (message.ytkVeliPanelimutabakat != null && message.hasOwnProperty("ytkVeliPanelimutabakat"))
            object.ytkVeliPanelimutabakat = message.ytkVeliPanelimutabakat;
        if (message.ytkVeliPanelibilgilendirme != null && message.hasOwnProperty("ytkVeliPanelibilgilendirme"))
            object.ytkVeliPanelibilgilendirme = message.ytkVeliPanelibilgilendirme;
        if (message.ytkVeliPaneliraporlar != null && message.hasOwnProperty("ytkVeliPaneliraporlar"))
            object.ytkVeliPaneliraporlar = message.ytkVeliPaneliraporlar;
        if (message.ytkYonetici != null && message.hasOwnProperty("ytkYonetici"))
            object.ytkYonetici = message.ytkYonetici;
        if (message.kId != null && message.hasOwnProperty("kId"))
            object.kId = message.kId;
        if (message.kOkulId != null && message.hasOwnProperty("kOkulId"))
            object.kOkulId = message.kOkulId;
        if (message.kAdiSoyadi != null && message.hasOwnProperty("kAdiSoyadi"))
            object.kAdiSoyadi = message.kAdiSoyadi;
        if (message.kSifre != null && message.hasOwnProperty("kSifre"))
            object.kSifre = message.kSifre;
        if (message.kTel != null && message.hasOwnProperty("kTel"))
            object.kTel = message.kTel;
        if (message.kMail != null && message.hasOwnProperty("kMail"))
            object.kMail = message.kMail;
        if (message.kSonGiris != null && message.hasOwnProperty("kSonGiris"))
            object.kSonGiris = message.kSonGiris;
        if (message.kKayitTarihi != null && message.hasOwnProperty("kKayitTarihi"))
            object.kKayitTarihi = message.kKayitTarihi;
        if (message.kTckn != null && message.hasOwnProperty("kTckn"))
            object.kTckn = message.kTckn;
        if (message.kKAdi != null && message.hasOwnProperty("kKAdi"))
            object.kKAdi = message.kKAdi;
        if (message.kTasarim != null && message.hasOwnProperty("kTasarim"))
            object.kTasarim = message.kTasarim;
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        return object;
    };

    /**
     * Converts this loginAndAuth to JSON.
     * @function toJSON
     * @memberof loginAndAuth
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    loginAndAuth.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return loginAndAuth;
})();

$root.ogrenci = (function() {

    /**
     * Properties of an ogrenci.
     * @exports Iogrenci
     * @interface Iogrenci
     * @property {number|null} [tip] ogrenci tip
     * @property {string|null} [ogrId] ogrenci ogrId
     * @property {string|null} [ogrOkulId] ogrenci ogrOkulId
     * @property {string|null} [ogrNo] ogrenci ogrNo
     * @property {string|null} [ogrAdiSoyadi] ogrenci ogrAdiSoyadi
     * @property {string|null} [ogrGrubu] ogrenci ogrGrubu
     * @property {number|null} [ogrGunlukLimit] ogrenci ogrGunlukLimit
     * @property {string|null} [ogrDogumTarihi] ogrenci ogrDogumTarihi
     * @property {string|null} [ogrTel] ogrenci ogrTel
     * @property {number|null} [ogrTip] ogrenci ogrTip
     * @property {string|null} [ogrKayitTarihi] ogrenci ogrKayitTarihi
     * @property {string|null} [ogrIskontoGrup] ogrenci ogrIskontoGrup
     * @property {string|null} [ogrKaydedenId] ogrenci ogrKaydedenId
     * @property {string|null} [ogrTckn] ogrenci ogrTckn
     * @property {string|null} [ogrSifre] ogrenci ogrSifre
     * @property {string|null} [ogrKAdi] ogrenci ogrKAdi
     * @property {string|null} [krtId] ogrenci krtId
     * @property {string|null} [krtNo] ogrenci krtNo
     * @property {string|null} [krtOkulId] ogrenci krtOkulId
     * @property {string|null} [krtOgrenciId] ogrenci krtOgrenciId
     * @property {string|null} [krtKayitTarihi] ogrenci krtKayitTarihi
     * @property {string|null} [krtDegistirmeTarihi] ogrenci krtDegistirmeTarihi
     * @property {number|null} [ogrPdksId] ogrenci ogrPdksId
     * @property {string|null} [ogrDurum] ogrenci ogrDurum
     * @property {string|null} [ogrVeli] ogrenci ogrVeli
     * @property {string|null} [ogrUrl] ogrenci ogrUrl
     */

    /**
     * Constructs a new ogrenci.
     * @exports ogrenci
     * @classdesc Represents an ogrenci.
     * @implements Iogrenci
     * @constructor
     * @param {Iogrenci=} [properties] Properties to set
     */
    function ogrenci(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ogrenci tip.
     * @member {number} tip
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.tip = 0;

    /**
     * ogrenci ogrId.
     * @member {string} ogrId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrId = "";

    /**
     * ogrenci ogrOkulId.
     * @member {string} ogrOkulId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrOkulId = "";

    /**
     * ogrenci ogrNo.
     * @member {string} ogrNo
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrNo = "";

    /**
     * ogrenci ogrAdiSoyadi.
     * @member {string} ogrAdiSoyadi
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrAdiSoyadi = "";

    /**
     * ogrenci ogrGrubu.
     * @member {string} ogrGrubu
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrGrubu = "";

    /**
     * ogrenci ogrGunlukLimit.
     * @member {number} ogrGunlukLimit
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrGunlukLimit = 0;

    /**
     * ogrenci ogrDogumTarihi.
     * @member {string} ogrDogumTarihi
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrDogumTarihi = "";

    /**
     * ogrenci ogrTel.
     * @member {string} ogrTel
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrTel = "";

    /**
     * ogrenci ogrTip.
     * @member {number} ogrTip
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrTip = 0;

    /**
     * ogrenci ogrKayitTarihi.
     * @member {string} ogrKayitTarihi
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrKayitTarihi = "";

    /**
     * ogrenci ogrIskontoGrup.
     * @member {string} ogrIskontoGrup
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrIskontoGrup = "";

    /**
     * ogrenci ogrKaydedenId.
     * @member {string} ogrKaydedenId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrKaydedenId = "";

    /**
     * ogrenci ogrTckn.
     * @member {string} ogrTckn
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrTckn = "";

    /**
     * ogrenci ogrSifre.
     * @member {string|null|undefined} ogrSifre
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrSifre = null;

    /**
     * ogrenci ogrKAdi.
     * @member {string|null|undefined} ogrKAdi
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrKAdi = null;

    /**
     * ogrenci krtId.
     * @member {string} krtId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.krtId = "";

    /**
     * ogrenci krtNo.
     * @member {string} krtNo
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.krtNo = "";

    /**
     * ogrenci krtOkulId.
     * @member {string} krtOkulId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.krtOkulId = "";

    /**
     * ogrenci krtOgrenciId.
     * @member {string} krtOgrenciId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.krtOgrenciId = "";

    /**
     * ogrenci krtKayitTarihi.
     * @member {string} krtKayitTarihi
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.krtKayitTarihi = "";

    /**
     * ogrenci krtDegistirmeTarihi.
     * @member {string} krtDegistirmeTarihi
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.krtDegistirmeTarihi = "";

    /**
     * ogrenci ogrPdksId.
     * @member {number} ogrPdksId
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrPdksId = 0;

    /**
     * ogrenci ogrDurum.
     * @member {string} ogrDurum
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrDurum = "";

    /**
     * ogrenci ogrVeli.
     * @member {string|null|undefined} ogrVeli
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrVeli = null;

    /**
     * ogrenci ogrUrl.
     * @member {string} ogrUrl
     * @memberof ogrenci
     * @instance
     */
    ogrenci.prototype.ogrUrl = "";

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * ogrenci _ogrSifre.
     * @member {"ogrSifre"|undefined} _ogrSifre
     * @memberof ogrenci
     * @instance
     */
    Object.defineProperty(ogrenci.prototype, "_ogrSifre", {
        get: $util.oneOfGetter($oneOfFields = ["ogrSifre"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * ogrenci _ogrKAdi.
     * @member {"ogrKAdi"|undefined} _ogrKAdi
     * @memberof ogrenci
     * @instance
     */
    Object.defineProperty(ogrenci.prototype, "_ogrKAdi", {
        get: $util.oneOfGetter($oneOfFields = ["ogrKAdi"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * ogrenci _ogrVeli.
     * @member {"ogrVeli"|undefined} _ogrVeli
     * @memberof ogrenci
     * @instance
     */
    Object.defineProperty(ogrenci.prototype, "_ogrVeli", {
        get: $util.oneOfGetter($oneOfFields = ["ogrVeli"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new ogrenci instance using the specified properties.
     * @function create
     * @memberof ogrenci
     * @static
     * @param {Iogrenci=} [properties] Properties to set
     * @returns {ogrenci} ogrenci instance
     */
    ogrenci.create = function create(properties) {
        return new ogrenci(properties);
    };

    /**
     * Encodes the specified ogrenci message. Does not implicitly {@link ogrenci.verify|verify} messages.
     * @function encode
     * @memberof ogrenci
     * @static
     * @param {Iogrenci} message ogrenci message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ogrenci.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tip);
        if (message.ogrId != null && Object.hasOwnProperty.call(message, "ogrId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.ogrId);
        if (message.ogrOkulId != null && Object.hasOwnProperty.call(message, "ogrOkulId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ogrOkulId);
        if (message.ogrNo != null && Object.hasOwnProperty.call(message, "ogrNo"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.ogrNo);
        if (message.ogrAdiSoyadi != null && Object.hasOwnProperty.call(message, "ogrAdiSoyadi"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ogrAdiSoyadi);
        if (message.ogrGrubu != null && Object.hasOwnProperty.call(message, "ogrGrubu"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.ogrGrubu);
        if (message.ogrGunlukLimit != null && Object.hasOwnProperty.call(message, "ogrGunlukLimit"))
            writer.uint32(/* id 7, wireType 5 =*/61).float(message.ogrGunlukLimit);
        if (message.ogrDogumTarihi != null && Object.hasOwnProperty.call(message, "ogrDogumTarihi"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.ogrDogumTarihi);
        if (message.ogrTel != null && Object.hasOwnProperty.call(message, "ogrTel"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.ogrTel);
        if (message.ogrTip != null && Object.hasOwnProperty.call(message, "ogrTip"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.ogrTip);
        if (message.ogrKayitTarihi != null && Object.hasOwnProperty.call(message, "ogrKayitTarihi"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.ogrKayitTarihi);
        if (message.ogrIskontoGrup != null && Object.hasOwnProperty.call(message, "ogrIskontoGrup"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.ogrIskontoGrup);
        if (message.ogrKaydedenId != null && Object.hasOwnProperty.call(message, "ogrKaydedenId"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.ogrKaydedenId);
        if (message.ogrTckn != null && Object.hasOwnProperty.call(message, "ogrTckn"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.ogrTckn);
        if (message.ogrSifre != null && Object.hasOwnProperty.call(message, "ogrSifre"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.ogrSifre);
        if (message.ogrKAdi != null && Object.hasOwnProperty.call(message, "ogrKAdi"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.ogrKAdi);
        if (message.krtId != null && Object.hasOwnProperty.call(message, "krtId"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.krtId);
        if (message.krtNo != null && Object.hasOwnProperty.call(message, "krtNo"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.krtNo);
        if (message.krtOkulId != null && Object.hasOwnProperty.call(message, "krtOkulId"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.krtOkulId);
        if (message.krtOgrenciId != null && Object.hasOwnProperty.call(message, "krtOgrenciId"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.krtOgrenciId);
        if (message.krtKayitTarihi != null && Object.hasOwnProperty.call(message, "krtKayitTarihi"))
            writer.uint32(/* id 21, wireType 2 =*/170).string(message.krtKayitTarihi);
        if (message.krtDegistirmeTarihi != null && Object.hasOwnProperty.call(message, "krtDegistirmeTarihi"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.krtDegistirmeTarihi);
        if (message.ogrPdksId != null && Object.hasOwnProperty.call(message, "ogrPdksId"))
            writer.uint32(/* id 23, wireType 0 =*/184).int32(message.ogrPdksId);
        if (message.ogrDurum != null && Object.hasOwnProperty.call(message, "ogrDurum"))
            writer.uint32(/* id 24, wireType 2 =*/194).string(message.ogrDurum);
        if (message.ogrVeli != null && Object.hasOwnProperty.call(message, "ogrVeli"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.ogrVeli);
        if (message.ogrUrl != null && Object.hasOwnProperty.call(message, "ogrUrl"))
            writer.uint32(/* id 26, wireType 2 =*/210).string(message.ogrUrl);
        return writer;
    };

    /**
     * Encodes the specified ogrenci message, length delimited. Does not implicitly {@link ogrenci.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ogrenci
     * @static
     * @param {Iogrenci} message ogrenci message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ogrenci.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ogrenci message from the specified reader or buffer.
     * @function decode
     * @memberof ogrenci
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ogrenci} ogrenci
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ogrenci.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ogrenci();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.tip = reader.int32();
                break;
            case 2:
                message.ogrId = reader.string();
                break;
            case 3:
                message.ogrOkulId = reader.string();
                break;
            case 4:
                message.ogrNo = reader.string();
                break;
            case 5:
                message.ogrAdiSoyadi = reader.string();
                break;
            case 6:
                message.ogrGrubu = reader.string();
                break;
            case 7:
                message.ogrGunlukLimit = reader.float();
                break;
            case 8:
                message.ogrDogumTarihi = reader.string();
                break;
            case 9:
                message.ogrTel = reader.string();
                break;
            case 10:
                message.ogrTip = reader.int32();
                break;
            case 11:
                message.ogrKayitTarihi = reader.string();
                break;
            case 12:
                message.ogrIskontoGrup = reader.string();
                break;
            case 13:
                message.ogrKaydedenId = reader.string();
                break;
            case 14:
                message.ogrTckn = reader.string();
                break;
            case 15:
                message.ogrSifre = reader.string();
                break;
            case 16:
                message.ogrKAdi = reader.string();
                break;
            case 17:
                message.krtId = reader.string();
                break;
            case 18:
                message.krtNo = reader.string();
                break;
            case 19:
                message.krtOkulId = reader.string();
                break;
            case 20:
                message.krtOgrenciId = reader.string();
                break;
            case 21:
                message.krtKayitTarihi = reader.string();
                break;
            case 22:
                message.krtDegistirmeTarihi = reader.string();
                break;
            case 23:
                message.ogrPdksId = reader.int32();
                break;
            case 24:
                message.ogrDurum = reader.string();
                break;
            case 25:
                message.ogrVeli = reader.string();
                break;
            case 26:
                message.ogrUrl = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ogrenci message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ogrenci
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ogrenci} ogrenci
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ogrenci.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ogrenci message.
     * @function verify
     * @memberof ogrenci
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ogrenci.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        if (message.ogrId != null && message.hasOwnProperty("ogrId"))
            if (!$util.isString(message.ogrId))
                return "ogrId: string expected";
        if (message.ogrOkulId != null && message.hasOwnProperty("ogrOkulId"))
            if (!$util.isString(message.ogrOkulId))
                return "ogrOkulId: string expected";
        if (message.ogrNo != null && message.hasOwnProperty("ogrNo"))
            if (!$util.isString(message.ogrNo))
                return "ogrNo: string expected";
        if (message.ogrAdiSoyadi != null && message.hasOwnProperty("ogrAdiSoyadi"))
            if (!$util.isString(message.ogrAdiSoyadi))
                return "ogrAdiSoyadi: string expected";
        if (message.ogrGrubu != null && message.hasOwnProperty("ogrGrubu"))
            if (!$util.isString(message.ogrGrubu))
                return "ogrGrubu: string expected";
        if (message.ogrGunlukLimit != null && message.hasOwnProperty("ogrGunlukLimit"))
            if (typeof message.ogrGunlukLimit !== "number")
                return "ogrGunlukLimit: number expected";
        if (message.ogrDogumTarihi != null && message.hasOwnProperty("ogrDogumTarihi"))
            if (!$util.isString(message.ogrDogumTarihi))
                return "ogrDogumTarihi: string expected";
        if (message.ogrTel != null && message.hasOwnProperty("ogrTel"))
            if (!$util.isString(message.ogrTel))
                return "ogrTel: string expected";
        if (message.ogrTip != null && message.hasOwnProperty("ogrTip"))
            if (!$util.isInteger(message.ogrTip))
                return "ogrTip: integer expected";
        if (message.ogrKayitTarihi != null && message.hasOwnProperty("ogrKayitTarihi"))
            if (!$util.isString(message.ogrKayitTarihi))
                return "ogrKayitTarihi: string expected";
        if (message.ogrIskontoGrup != null && message.hasOwnProperty("ogrIskontoGrup"))
            if (!$util.isString(message.ogrIskontoGrup))
                return "ogrIskontoGrup: string expected";
        if (message.ogrKaydedenId != null && message.hasOwnProperty("ogrKaydedenId"))
            if (!$util.isString(message.ogrKaydedenId))
                return "ogrKaydedenId: string expected";
        if (message.ogrTckn != null && message.hasOwnProperty("ogrTckn"))
            if (!$util.isString(message.ogrTckn))
                return "ogrTckn: string expected";
        if (message.ogrSifre != null && message.hasOwnProperty("ogrSifre")) {
            properties._ogrSifre = 1;
            if (!$util.isString(message.ogrSifre))
                return "ogrSifre: string expected";
        }
        if (message.ogrKAdi != null && message.hasOwnProperty("ogrKAdi")) {
            properties._ogrKAdi = 1;
            if (!$util.isString(message.ogrKAdi))
                return "ogrKAdi: string expected";
        }
        if (message.krtId != null && message.hasOwnProperty("krtId"))
            if (!$util.isString(message.krtId))
                return "krtId: string expected";
        if (message.krtNo != null && message.hasOwnProperty("krtNo"))
            if (!$util.isString(message.krtNo))
                return "krtNo: string expected";
        if (message.krtOkulId != null && message.hasOwnProperty("krtOkulId"))
            if (!$util.isString(message.krtOkulId))
                return "krtOkulId: string expected";
        if (message.krtOgrenciId != null && message.hasOwnProperty("krtOgrenciId"))
            if (!$util.isString(message.krtOgrenciId))
                return "krtOgrenciId: string expected";
        if (message.krtKayitTarihi != null && message.hasOwnProperty("krtKayitTarihi"))
            if (!$util.isString(message.krtKayitTarihi))
                return "krtKayitTarihi: string expected";
        if (message.krtDegistirmeTarihi != null && message.hasOwnProperty("krtDegistirmeTarihi"))
            if (!$util.isString(message.krtDegistirmeTarihi))
                return "krtDegistirmeTarihi: string expected";
        if (message.ogrPdksId != null && message.hasOwnProperty("ogrPdksId"))
            if (!$util.isInteger(message.ogrPdksId))
                return "ogrPdksId: integer expected";
        if (message.ogrDurum != null && message.hasOwnProperty("ogrDurum"))
            if (!$util.isString(message.ogrDurum))
                return "ogrDurum: string expected";
        if (message.ogrVeli != null && message.hasOwnProperty("ogrVeli")) {
            properties._ogrVeli = 1;
            if (!$util.isString(message.ogrVeli))
                return "ogrVeli: string expected";
        }
        if (message.ogrUrl != null && message.hasOwnProperty("ogrUrl"))
            if (!$util.isString(message.ogrUrl))
                return "ogrUrl: string expected";
        return null;
    };

    /**
     * Creates an ogrenci message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ogrenci
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ogrenci} ogrenci
     */
    ogrenci.fromObject = function fromObject(object) {
        if (object instanceof $root.ogrenci)
            return object;
        var message = new $root.ogrenci();
        if (object.tip != null)
            message.tip = object.tip | 0;
        if (object.ogrId != null)
            message.ogrId = String(object.ogrId);
        if (object.ogrOkulId != null)
            message.ogrOkulId = String(object.ogrOkulId);
        if (object.ogrNo != null)
            message.ogrNo = String(object.ogrNo);
        if (object.ogrAdiSoyadi != null)
            message.ogrAdiSoyadi = String(object.ogrAdiSoyadi);
        if (object.ogrGrubu != null)
            message.ogrGrubu = String(object.ogrGrubu);
        if (object.ogrGunlukLimit != null)
            message.ogrGunlukLimit = Number(object.ogrGunlukLimit);
        if (object.ogrDogumTarihi != null)
            message.ogrDogumTarihi = String(object.ogrDogumTarihi);
        if (object.ogrTel != null)
            message.ogrTel = String(object.ogrTel);
        if (object.ogrTip != null)
            message.ogrTip = object.ogrTip | 0;
        if (object.ogrKayitTarihi != null)
            message.ogrKayitTarihi = String(object.ogrKayitTarihi);
        if (object.ogrIskontoGrup != null)
            message.ogrIskontoGrup = String(object.ogrIskontoGrup);
        if (object.ogrKaydedenId != null)
            message.ogrKaydedenId = String(object.ogrKaydedenId);
        if (object.ogrTckn != null)
            message.ogrTckn = String(object.ogrTckn);
        if (object.ogrSifre != null)
            message.ogrSifre = String(object.ogrSifre);
        if (object.ogrKAdi != null)
            message.ogrKAdi = String(object.ogrKAdi);
        if (object.krtId != null)
            message.krtId = String(object.krtId);
        if (object.krtNo != null)
            message.krtNo = String(object.krtNo);
        if (object.krtOkulId != null)
            message.krtOkulId = String(object.krtOkulId);
        if (object.krtOgrenciId != null)
            message.krtOgrenciId = String(object.krtOgrenciId);
        if (object.krtKayitTarihi != null)
            message.krtKayitTarihi = String(object.krtKayitTarihi);
        if (object.krtDegistirmeTarihi != null)
            message.krtDegistirmeTarihi = String(object.krtDegistirmeTarihi);
        if (object.ogrPdksId != null)
            message.ogrPdksId = object.ogrPdksId | 0;
        if (object.ogrDurum != null)
            message.ogrDurum = String(object.ogrDurum);
        if (object.ogrVeli != null)
            message.ogrVeli = String(object.ogrVeli);
        if (object.ogrUrl != null)
            message.ogrUrl = String(object.ogrUrl);
        return message;
    };

    /**
     * Creates a plain object from an ogrenci message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ogrenci
     * @static
     * @param {ogrenci} message ogrenci
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ogrenci.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.tip = 0;
            object.ogrId = "";
            object.ogrOkulId = "";
            object.ogrNo = "";
            object.ogrAdiSoyadi = "";
            object.ogrGrubu = "";
            object.ogrGunlukLimit = 0;
            object.ogrDogumTarihi = "";
            object.ogrTel = "";
            object.ogrTip = 0;
            object.ogrKayitTarihi = "";
            object.ogrIskontoGrup = "";
            object.ogrKaydedenId = "";
            object.ogrTckn = "";
            object.krtId = "";
            object.krtNo = "";
            object.krtOkulId = "";
            object.krtOgrenciId = "";
            object.krtKayitTarihi = "";
            object.krtDegistirmeTarihi = "";
            object.ogrPdksId = 0;
            object.ogrDurum = "";
            object.ogrUrl = "";
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        if (message.ogrId != null && message.hasOwnProperty("ogrId"))
            object.ogrId = message.ogrId;
        if (message.ogrOkulId != null && message.hasOwnProperty("ogrOkulId"))
            object.ogrOkulId = message.ogrOkulId;
        if (message.ogrNo != null && message.hasOwnProperty("ogrNo"))
            object.ogrNo = message.ogrNo;
        if (message.ogrAdiSoyadi != null && message.hasOwnProperty("ogrAdiSoyadi"))
            object.ogrAdiSoyadi = message.ogrAdiSoyadi;
        if (message.ogrGrubu != null && message.hasOwnProperty("ogrGrubu"))
            object.ogrGrubu = message.ogrGrubu;
        if (message.ogrGunlukLimit != null && message.hasOwnProperty("ogrGunlukLimit"))
            object.ogrGunlukLimit = options.json && !isFinite(message.ogrGunlukLimit) ? String(message.ogrGunlukLimit) : message.ogrGunlukLimit;
        if (message.ogrDogumTarihi != null && message.hasOwnProperty("ogrDogumTarihi"))
            object.ogrDogumTarihi = message.ogrDogumTarihi;
        if (message.ogrTel != null && message.hasOwnProperty("ogrTel"))
            object.ogrTel = message.ogrTel;
        if (message.ogrTip != null && message.hasOwnProperty("ogrTip"))
            object.ogrTip = message.ogrTip;
        if (message.ogrKayitTarihi != null && message.hasOwnProperty("ogrKayitTarihi"))
            object.ogrKayitTarihi = message.ogrKayitTarihi;
        if (message.ogrIskontoGrup != null && message.hasOwnProperty("ogrIskontoGrup"))
            object.ogrIskontoGrup = message.ogrIskontoGrup;
        if (message.ogrKaydedenId != null && message.hasOwnProperty("ogrKaydedenId"))
            object.ogrKaydedenId = message.ogrKaydedenId;
        if (message.ogrTckn != null && message.hasOwnProperty("ogrTckn"))
            object.ogrTckn = message.ogrTckn;
        if (message.ogrSifre != null && message.hasOwnProperty("ogrSifre")) {
            object.ogrSifre = message.ogrSifre;
            if (options.oneofs)
                object._ogrSifre = "ogrSifre";
        }
        if (message.ogrKAdi != null && message.hasOwnProperty("ogrKAdi")) {
            object.ogrKAdi = message.ogrKAdi;
            if (options.oneofs)
                object._ogrKAdi = "ogrKAdi";
        }
        if (message.krtId != null && message.hasOwnProperty("krtId"))
            object.krtId = message.krtId;
        if (message.krtNo != null && message.hasOwnProperty("krtNo"))
            object.krtNo = message.krtNo;
        if (message.krtOkulId != null && message.hasOwnProperty("krtOkulId"))
            object.krtOkulId = message.krtOkulId;
        if (message.krtOgrenciId != null && message.hasOwnProperty("krtOgrenciId"))
            object.krtOgrenciId = message.krtOgrenciId;
        if (message.krtKayitTarihi != null && message.hasOwnProperty("krtKayitTarihi"))
            object.krtKayitTarihi = message.krtKayitTarihi;
        if (message.krtDegistirmeTarihi != null && message.hasOwnProperty("krtDegistirmeTarihi"))
            object.krtDegistirmeTarihi = message.krtDegistirmeTarihi;
        if (message.ogrPdksId != null && message.hasOwnProperty("ogrPdksId"))
            object.ogrPdksId = message.ogrPdksId;
        if (message.ogrDurum != null && message.hasOwnProperty("ogrDurum"))
            object.ogrDurum = message.ogrDurum;
        if (message.ogrVeli != null && message.hasOwnProperty("ogrVeli")) {
            object.ogrVeli = message.ogrVeli;
            if (options.oneofs)
                object._ogrVeli = "ogrVeli";
        }
        if (message.ogrUrl != null && message.hasOwnProperty("ogrUrl"))
            object.ogrUrl = message.ogrUrl;
        return object;
    };

    /**
     * Converts this ogrenci to JSON.
     * @function toJSON
     * @memberof ogrenci
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ogrenci.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ogrenci;
})();

$root.ogrenciQuery = (function() {

    /**
     * Properties of an ogrenciQuery.
     * @exports IogrenciQuery
     * @interface IogrenciQuery
     * @property {number|null} [tip] ogrenciQuery tip
     * @property {string|null} [okulId] ogrenciQuery okulId
     * @property {Array.<Iogrenci>|null} [sonuc] ogrenciQuery sonuc
     */

    /**
     * Constructs a new ogrenciQuery.
     * @exports ogrenciQuery
     * @classdesc Represents an ogrenciQuery.
     * @implements IogrenciQuery
     * @constructor
     * @param {IogrenciQuery=} [properties] Properties to set
     */
    function ogrenciQuery(properties) {
        this.sonuc = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ogrenciQuery tip.
     * @member {number} tip
     * @memberof ogrenciQuery
     * @instance
     */
    ogrenciQuery.prototype.tip = 0;

    /**
     * ogrenciQuery okulId.
     * @member {string} okulId
     * @memberof ogrenciQuery
     * @instance
     */
    ogrenciQuery.prototype.okulId = "";

    /**
     * ogrenciQuery sonuc.
     * @member {Array.<Iogrenci>} sonuc
     * @memberof ogrenciQuery
     * @instance
     */
    ogrenciQuery.prototype.sonuc = $util.emptyArray;

    /**
     * Creates a new ogrenciQuery instance using the specified properties.
     * @function create
     * @memberof ogrenciQuery
     * @static
     * @param {IogrenciQuery=} [properties] Properties to set
     * @returns {ogrenciQuery} ogrenciQuery instance
     */
    ogrenciQuery.create = function create(properties) {
        return new ogrenciQuery(properties);
    };

    /**
     * Encodes the specified ogrenciQuery message. Does not implicitly {@link ogrenciQuery.verify|verify} messages.
     * @function encode
     * @memberof ogrenciQuery
     * @static
     * @param {IogrenciQuery} message ogrenciQuery message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ogrenciQuery.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tip);
        if (message.okulId != null && Object.hasOwnProperty.call(message, "okulId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.okulId);
        if (message.sonuc != null && message.sonuc.length)
            for (var i = 0; i < message.sonuc.length; ++i)
                $root.ogrenci.encode(message.sonuc[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ogrenciQuery message, length delimited. Does not implicitly {@link ogrenciQuery.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ogrenciQuery
     * @static
     * @param {IogrenciQuery} message ogrenciQuery message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ogrenciQuery.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ogrenciQuery message from the specified reader or buffer.
     * @function decode
     * @memberof ogrenciQuery
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ogrenciQuery} ogrenciQuery
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ogrenciQuery.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ogrenciQuery();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.tip = reader.int32();
                break;
            case 2:
                message.okulId = reader.string();
                break;
            case 3:
                if (!(message.sonuc && message.sonuc.length))
                    message.sonuc = [];
                message.sonuc.push($root.ogrenci.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an ogrenciQuery message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ogrenciQuery
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ogrenciQuery} ogrenciQuery
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ogrenciQuery.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ogrenciQuery message.
     * @function verify
     * @memberof ogrenciQuery
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ogrenciQuery.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            if (!$util.isString(message.okulId))
                return "okulId: string expected";
        if (message.sonuc != null && message.hasOwnProperty("sonuc")) {
            if (!Array.isArray(message.sonuc))
                return "sonuc: array expected";
            for (var i = 0; i < message.sonuc.length; ++i) {
                var error = $root.ogrenci.verify(message.sonuc[i]);
                if (error)
                    return "sonuc." + error;
            }
        }
        return null;
    };

    /**
     * Creates an ogrenciQuery message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ogrenciQuery
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ogrenciQuery} ogrenciQuery
     */
    ogrenciQuery.fromObject = function fromObject(object) {
        if (object instanceof $root.ogrenciQuery)
            return object;
        var message = new $root.ogrenciQuery();
        if (object.tip != null)
            message.tip = object.tip | 0;
        if (object.okulId != null)
            message.okulId = String(object.okulId);
        if (object.sonuc) {
            if (!Array.isArray(object.sonuc))
                throw TypeError(".ogrenciQuery.sonuc: array expected");
            message.sonuc = [];
            for (var i = 0; i < object.sonuc.length; ++i) {
                if (typeof object.sonuc[i] !== "object")
                    throw TypeError(".ogrenciQuery.sonuc: object expected");
                message.sonuc[i] = $root.ogrenci.fromObject(object.sonuc[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from an ogrenciQuery message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ogrenciQuery
     * @static
     * @param {ogrenciQuery} message ogrenciQuery
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ogrenciQuery.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.sonuc = [];
        if (options.defaults) {
            object.tip = 0;
            object.okulId = "";
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            object.okulId = message.okulId;
        if (message.sonuc && message.sonuc.length) {
            object.sonuc = [];
            for (var j = 0; j < message.sonuc.length; ++j)
                object.sonuc[j] = $root.ogrenci.toObject(message.sonuc[j], options);
        }
        return object;
    };

    /**
     * Converts this ogrenciQuery to JSON.
     * @function toJSON
     * @memberof ogrenciQuery
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ogrenciQuery.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return ogrenciQuery;
})();

$root.stokIcerik = (function() {

    /**
     * Properties of a stokIcerik.
     * @exports IstokIcerik
     * @interface IstokIcerik
     * @property {string|null} [stId] stokIcerik stId
     * @property {string|null} [stAdi] stokIcerik stAdi
     * @property {string|null} [stBarkod] stokIcerik stBarkod
     * @property {string|null} [stGrubu] stokIcerik stGrubu
     * @property {number|null} [stMin] stokIcerik stMin
     * @property {string|null} [stBirim] stokIcerik stBirim
     * @property {string|null} [stResim] stokIcerik stResim
     * @property {string|null} [stKayitTarihi] stokIcerik stKayitTarihi
     * @property {string|null} [stIskontoGrup] stokIcerik stIskontoGrup
     * @property {string|null} [stKaydedenId] stokIcerik stKaydedenId
     * @property {string|null} [stfId] stokIcerik stfId
     * @property {number|null} [stfTip] stokIcerik stfTip
     * @property {number|null} [stfFiyat] stokIcerik stfFiyat
     * @property {string|null} [stfKayitTarihi] stokIcerik stfKayitTarihi
     * @property {string|null} [stfKaydedenId] stokIcerik stfKaydedenId
     */

    /**
     * Constructs a new stokIcerik.
     * @exports stokIcerik
     * @classdesc Represents a stokIcerik.
     * @implements IstokIcerik
     * @constructor
     * @param {IstokIcerik=} [properties] Properties to set
     */
    function stokIcerik(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * stokIcerik stId.
     * @member {string} stId
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stId = "";

    /**
     * stokIcerik stAdi.
     * @member {string} stAdi
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stAdi = "";

    /**
     * stokIcerik stBarkod.
     * @member {string} stBarkod
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stBarkod = "";

    /**
     * stokIcerik stGrubu.
     * @member {string} stGrubu
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stGrubu = "";

    /**
     * stokIcerik stMin.
     * @member {number} stMin
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stMin = 0;

    /**
     * stokIcerik stBirim.
     * @member {string} stBirim
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stBirim = "";

    /**
     * stokIcerik stResim.
     * @member {string} stResim
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stResim = "";

    /**
     * stokIcerik stKayitTarihi.
     * @member {string} stKayitTarihi
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stKayitTarihi = "";

    /**
     * stokIcerik stIskontoGrup.
     * @member {string} stIskontoGrup
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stIskontoGrup = "";

    /**
     * stokIcerik stKaydedenId.
     * @member {string} stKaydedenId
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stKaydedenId = "";

    /**
     * stokIcerik stfId.
     * @member {string} stfId
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stfId = "";

    /**
     * stokIcerik stfTip.
     * @member {number} stfTip
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stfTip = 0;

    /**
     * stokIcerik stfFiyat.
     * @member {number} stfFiyat
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stfFiyat = 0;

    /**
     * stokIcerik stfKayitTarihi.
     * @member {string} stfKayitTarihi
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stfKayitTarihi = "";

    /**
     * stokIcerik stfKaydedenId.
     * @member {string} stfKaydedenId
     * @memberof stokIcerik
     * @instance
     */
    stokIcerik.prototype.stfKaydedenId = "";

    /**
     * Creates a new stokIcerik instance using the specified properties.
     * @function create
     * @memberof stokIcerik
     * @static
     * @param {IstokIcerik=} [properties] Properties to set
     * @returns {stokIcerik} stokIcerik instance
     */
    stokIcerik.create = function create(properties) {
        return new stokIcerik(properties);
    };

    /**
     * Encodes the specified stokIcerik message. Does not implicitly {@link stokIcerik.verify|verify} messages.
     * @function encode
     * @memberof stokIcerik
     * @static
     * @param {IstokIcerik} message stokIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    stokIcerik.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.stId != null && Object.hasOwnProperty.call(message, "stId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.stId);
        if (message.stAdi != null && Object.hasOwnProperty.call(message, "stAdi"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.stAdi);
        if (message.stBarkod != null && Object.hasOwnProperty.call(message, "stBarkod"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.stBarkod);
        if (message.stGrubu != null && Object.hasOwnProperty.call(message, "stGrubu"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.stGrubu);
        if (message.stMin != null && Object.hasOwnProperty.call(message, "stMin"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.stMin);
        if (message.stBirim != null && Object.hasOwnProperty.call(message, "stBirim"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.stBirim);
        if (message.stResim != null && Object.hasOwnProperty.call(message, "stResim"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.stResim);
        if (message.stKayitTarihi != null && Object.hasOwnProperty.call(message, "stKayitTarihi"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.stKayitTarihi);
        if (message.stIskontoGrup != null && Object.hasOwnProperty.call(message, "stIskontoGrup"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.stIskontoGrup);
        if (message.stKaydedenId != null && Object.hasOwnProperty.call(message, "stKaydedenId"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.stKaydedenId);
        if (message.stfId != null && Object.hasOwnProperty.call(message, "stfId"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.stfId);
        if (message.stfTip != null && Object.hasOwnProperty.call(message, "stfTip"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.stfTip);
        if (message.stfFiyat != null && Object.hasOwnProperty.call(message, "stfFiyat"))
            writer.uint32(/* id 14, wireType 5 =*/117).float(message.stfFiyat);
        if (message.stfKayitTarihi != null && Object.hasOwnProperty.call(message, "stfKayitTarihi"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.stfKayitTarihi);
        if (message.stfKaydedenId != null && Object.hasOwnProperty.call(message, "stfKaydedenId"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.stfKaydedenId);
        return writer;
    };

    /**
     * Encodes the specified stokIcerik message, length delimited. Does not implicitly {@link stokIcerik.verify|verify} messages.
     * @function encodeDelimited
     * @memberof stokIcerik
     * @static
     * @param {IstokIcerik} message stokIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    stokIcerik.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a stokIcerik message from the specified reader or buffer.
     * @function decode
     * @memberof stokIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {stokIcerik} stokIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    stokIcerik.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stokIcerik();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 2:
                message.stId = reader.string();
                break;
            case 3:
                message.stAdi = reader.string();
                break;
            case 4:
                message.stBarkod = reader.string();
                break;
            case 5:
                message.stGrubu = reader.string();
                break;
            case 6:
                message.stMin = reader.int32();
                break;
            case 7:
                message.stBirim = reader.string();
                break;
            case 8:
                message.stResim = reader.string();
                break;
            case 9:
                message.stKayitTarihi = reader.string();
                break;
            case 10:
                message.stIskontoGrup = reader.string();
                break;
            case 11:
                message.stKaydedenId = reader.string();
                break;
            case 12:
                message.stfId = reader.string();
                break;
            case 13:
                message.stfTip = reader.int32();
                break;
            case 14:
                message.stfFiyat = reader.float();
                break;
            case 15:
                message.stfKayitTarihi = reader.string();
                break;
            case 16:
                message.stfKaydedenId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a stokIcerik message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof stokIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {stokIcerik} stokIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    stokIcerik.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a stokIcerik message.
     * @function verify
     * @memberof stokIcerik
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    stokIcerik.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.stId != null && message.hasOwnProperty("stId"))
            if (!$util.isString(message.stId))
                return "stId: string expected";
        if (message.stAdi != null && message.hasOwnProperty("stAdi"))
            if (!$util.isString(message.stAdi))
                return "stAdi: string expected";
        if (message.stBarkod != null && message.hasOwnProperty("stBarkod"))
            if (!$util.isString(message.stBarkod))
                return "stBarkod: string expected";
        if (message.stGrubu != null && message.hasOwnProperty("stGrubu"))
            if (!$util.isString(message.stGrubu))
                return "stGrubu: string expected";
        if (message.stMin != null && message.hasOwnProperty("stMin"))
            if (!$util.isInteger(message.stMin))
                return "stMin: integer expected";
        if (message.stBirim != null && message.hasOwnProperty("stBirim"))
            if (!$util.isString(message.stBirim))
                return "stBirim: string expected";
        if (message.stResim != null && message.hasOwnProperty("stResim"))
            if (!$util.isString(message.stResim))
                return "stResim: string expected";
        if (message.stKayitTarihi != null && message.hasOwnProperty("stKayitTarihi"))
            if (!$util.isString(message.stKayitTarihi))
                return "stKayitTarihi: string expected";
        if (message.stIskontoGrup != null && message.hasOwnProperty("stIskontoGrup"))
            if (!$util.isString(message.stIskontoGrup))
                return "stIskontoGrup: string expected";
        if (message.stKaydedenId != null && message.hasOwnProperty("stKaydedenId"))
            if (!$util.isString(message.stKaydedenId))
                return "stKaydedenId: string expected";
        if (message.stfId != null && message.hasOwnProperty("stfId"))
            if (!$util.isString(message.stfId))
                return "stfId: string expected";
        if (message.stfTip != null && message.hasOwnProperty("stfTip"))
            if (!$util.isInteger(message.stfTip))
                return "stfTip: integer expected";
        if (message.stfFiyat != null && message.hasOwnProperty("stfFiyat"))
            if (typeof message.stfFiyat !== "number")
                return "stfFiyat: number expected";
        if (message.stfKayitTarihi != null && message.hasOwnProperty("stfKayitTarihi"))
            if (!$util.isString(message.stfKayitTarihi))
                return "stfKayitTarihi: string expected";
        if (message.stfKaydedenId != null && message.hasOwnProperty("stfKaydedenId"))
            if (!$util.isString(message.stfKaydedenId))
                return "stfKaydedenId: string expected";
        return null;
    };

    /**
     * Creates a stokIcerik message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof stokIcerik
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {stokIcerik} stokIcerik
     */
    stokIcerik.fromObject = function fromObject(object) {
        if (object instanceof $root.stokIcerik)
            return object;
        var message = new $root.stokIcerik();
        if (object.stId != null)
            message.stId = String(object.stId);
        if (object.stAdi != null)
            message.stAdi = String(object.stAdi);
        if (object.stBarkod != null)
            message.stBarkod = String(object.stBarkod);
        if (object.stGrubu != null)
            message.stGrubu = String(object.stGrubu);
        if (object.stMin != null)
            message.stMin = object.stMin | 0;
        if (object.stBirim != null)
            message.stBirim = String(object.stBirim);
        if (object.stResim != null)
            message.stResim = String(object.stResim);
        if (object.stKayitTarihi != null)
            message.stKayitTarihi = String(object.stKayitTarihi);
        if (object.stIskontoGrup != null)
            message.stIskontoGrup = String(object.stIskontoGrup);
        if (object.stKaydedenId != null)
            message.stKaydedenId = String(object.stKaydedenId);
        if (object.stfId != null)
            message.stfId = String(object.stfId);
        if (object.stfTip != null)
            message.stfTip = object.stfTip | 0;
        if (object.stfFiyat != null)
            message.stfFiyat = Number(object.stfFiyat);
        if (object.stfKayitTarihi != null)
            message.stfKayitTarihi = String(object.stfKayitTarihi);
        if (object.stfKaydedenId != null)
            message.stfKaydedenId = String(object.stfKaydedenId);
        return message;
    };

    /**
     * Creates a plain object from a stokIcerik message. Also converts values to other types if specified.
     * @function toObject
     * @memberof stokIcerik
     * @static
     * @param {stokIcerik} message stokIcerik
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    stokIcerik.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.stId = "";
            object.stAdi = "";
            object.stBarkod = "";
            object.stGrubu = "";
            object.stMin = 0;
            object.stBirim = "";
            object.stResim = "";
            object.stKayitTarihi = "";
            object.stIskontoGrup = "";
            object.stKaydedenId = "";
            object.stfId = "";
            object.stfTip = 0;
            object.stfFiyat = 0;
            object.stfKayitTarihi = "";
            object.stfKaydedenId = "";
        }
        if (message.stId != null && message.hasOwnProperty("stId"))
            object.stId = message.stId;
        if (message.stAdi != null && message.hasOwnProperty("stAdi"))
            object.stAdi = message.stAdi;
        if (message.stBarkod != null && message.hasOwnProperty("stBarkod"))
            object.stBarkod = message.stBarkod;
        if (message.stGrubu != null && message.hasOwnProperty("stGrubu"))
            object.stGrubu = message.stGrubu;
        if (message.stMin != null && message.hasOwnProperty("stMin"))
            object.stMin = message.stMin;
        if (message.stBirim != null && message.hasOwnProperty("stBirim"))
            object.stBirim = message.stBirim;
        if (message.stResim != null && message.hasOwnProperty("stResim"))
            object.stResim = message.stResim;
        if (message.stKayitTarihi != null && message.hasOwnProperty("stKayitTarihi"))
            object.stKayitTarihi = message.stKayitTarihi;
        if (message.stIskontoGrup != null && message.hasOwnProperty("stIskontoGrup"))
            object.stIskontoGrup = message.stIskontoGrup;
        if (message.stKaydedenId != null && message.hasOwnProperty("stKaydedenId"))
            object.stKaydedenId = message.stKaydedenId;
        if (message.stfId != null && message.hasOwnProperty("stfId"))
            object.stfId = message.stfId;
        if (message.stfTip != null && message.hasOwnProperty("stfTip"))
            object.stfTip = message.stfTip;
        if (message.stfFiyat != null && message.hasOwnProperty("stfFiyat"))
            object.stfFiyat = options.json && !isFinite(message.stfFiyat) ? String(message.stfFiyat) : message.stfFiyat;
        if (message.stfKayitTarihi != null && message.hasOwnProperty("stfKayitTarihi"))
            object.stfKayitTarihi = message.stfKayitTarihi;
        if (message.stfKaydedenId != null && message.hasOwnProperty("stfKaydedenId"))
            object.stfKaydedenId = message.stfKaydedenId;
        return object;
    };

    /**
     * Converts this stokIcerik to JSON.
     * @function toJSON
     * @memberof stokIcerik
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    stokIcerik.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return stokIcerik;
})();

$root.stok = (function() {

    /**
     * Properties of a stok.
     * @exports Istok
     * @interface Istok
     * @property {number|null} [tip] stok tip
     * @property {string|null} [urunler] stok urunler
     * @property {string|null} [okulId] stok okulId
     * @property {Array.<IstokIcerik>|null} [sonuc] stok sonuc
     */

    /**
     * Constructs a new stok.
     * @exports stok
     * @classdesc Represents a stok.
     * @implements Istok
     * @constructor
     * @param {Istok=} [properties] Properties to set
     */
    function stok(properties) {
        this.sonuc = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * stok tip.
     * @member {number} tip
     * @memberof stok
     * @instance
     */
    stok.prototype.tip = 0;

    /**
     * stok urunler.
     * @member {string} urunler
     * @memberof stok
     * @instance
     */
    stok.prototype.urunler = "";

    /**
     * stok okulId.
     * @member {string} okulId
     * @memberof stok
     * @instance
     */
    stok.prototype.okulId = "";

    /**
     * stok sonuc.
     * @member {Array.<IstokIcerik>} sonuc
     * @memberof stok
     * @instance
     */
    stok.prototype.sonuc = $util.emptyArray;

    /**
     * Creates a new stok instance using the specified properties.
     * @function create
     * @memberof stok
     * @static
     * @param {Istok=} [properties] Properties to set
     * @returns {stok} stok instance
     */
    stok.create = function create(properties) {
        return new stok(properties);
    };

    /**
     * Encodes the specified stok message. Does not implicitly {@link stok.verify|verify} messages.
     * @function encode
     * @memberof stok
     * @static
     * @param {Istok} message stok message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    stok.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tip);
        if (message.urunler != null && Object.hasOwnProperty.call(message, "urunler"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.urunler);
        if (message.okulId != null && Object.hasOwnProperty.call(message, "okulId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.okulId);
        if (message.sonuc != null && message.sonuc.length)
            for (var i = 0; i < message.sonuc.length; ++i)
                $root.stokIcerik.encode(message.sonuc[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified stok message, length delimited. Does not implicitly {@link stok.verify|verify} messages.
     * @function encodeDelimited
     * @memberof stok
     * @static
     * @param {Istok} message stok message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    stok.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a stok message from the specified reader or buffer.
     * @function decode
     * @memberof stok
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {stok} stok
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    stok.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.stok();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.tip = reader.int32();
                break;
            case 2:
                message.urunler = reader.string();
                break;
            case 3:
                message.okulId = reader.string();
                break;
            case 4:
                if (!(message.sonuc && message.sonuc.length))
                    message.sonuc = [];
                message.sonuc.push($root.stokIcerik.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a stok message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof stok
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {stok} stok
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    stok.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a stok message.
     * @function verify
     * @memberof stok
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    stok.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        if (message.urunler != null && message.hasOwnProperty("urunler"))
            if (!$util.isString(message.urunler))
                return "urunler: string expected";
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            if (!$util.isString(message.okulId))
                return "okulId: string expected";
        if (message.sonuc != null && message.hasOwnProperty("sonuc")) {
            if (!Array.isArray(message.sonuc))
                return "sonuc: array expected";
            for (var i = 0; i < message.sonuc.length; ++i) {
                var error = $root.stokIcerik.verify(message.sonuc[i]);
                if (error)
                    return "sonuc." + error;
            }
        }
        return null;
    };

    /**
     * Creates a stok message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof stok
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {stok} stok
     */
    stok.fromObject = function fromObject(object) {
        if (object instanceof $root.stok)
            return object;
        var message = new $root.stok();
        if (object.tip != null)
            message.tip = object.tip | 0;
        if (object.urunler != null)
            message.urunler = String(object.urunler);
        if (object.okulId != null)
            message.okulId = String(object.okulId);
        if (object.sonuc) {
            if (!Array.isArray(object.sonuc))
                throw TypeError(".stok.sonuc: array expected");
            message.sonuc = [];
            for (var i = 0; i < object.sonuc.length; ++i) {
                if (typeof object.sonuc[i] !== "object")
                    throw TypeError(".stok.sonuc: object expected");
                message.sonuc[i] = $root.stokIcerik.fromObject(object.sonuc[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a stok message. Also converts values to other types if specified.
     * @function toObject
     * @memberof stok
     * @static
     * @param {stok} message stok
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    stok.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.sonuc = [];
        if (options.defaults) {
            object.tip = 0;
            object.urunler = "";
            object.okulId = "";
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        if (message.urunler != null && message.hasOwnProperty("urunler"))
            object.urunler = message.urunler;
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            object.okulId = message.okulId;
        if (message.sonuc && message.sonuc.length) {
            object.sonuc = [];
            for (var j = 0; j < message.sonuc.length; ++j)
                object.sonuc[j] = $root.stokIcerik.toObject(message.sonuc[j], options);
        }
        return object;
    };

    /**
     * Converts this stok to JSON.
     * @function toJSON
     * @memberof stok
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    stok.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return stok;
})();

$root.sepetIcerik = (function() {

    /**
     * Properties of a sepetIcerik.
     * @exports IsepetIcerik
     * @interface IsepetIcerik
     * @property {string|null} [sptId] sepetIcerik sptId
     * @property {string|null} [sptEid] sepetIcerik sptEid
     * @property {string|null} [sptOkulId] sepetIcerik sptOkulId
     * @property {string|null} [sptKaydedenId] sepetIcerik sptKaydedenId
     * @property {string|null} [sptUId] sepetIcerik sptUId
     * @property {number|null} [sptUTip] sepetIcerik sptUTip
     * @property {number|null} [sptMiktar] sepetIcerik sptMiktar
     * @property {number|null} [sptTip] sepetIcerik sptTip
     * @property {number|null} [cevapTip] sepetIcerik cevapTip
     * @property {string|null} [cevap] sepetIcerik cevap
     */

    /**
     * Constructs a new sepetIcerik.
     * @exports sepetIcerik
     * @classdesc Represents a sepetIcerik.
     * @implements IsepetIcerik
     * @constructor
     * @param {IsepetIcerik=} [properties] Properties to set
     */
    function sepetIcerik(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * sepetIcerik sptId.
     * @member {string} sptId
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptId = "";

    /**
     * sepetIcerik sptEid.
     * @member {string} sptEid
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptEid = "";

    /**
     * sepetIcerik sptOkulId.
     * @member {string} sptOkulId
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptOkulId = "";

    /**
     * sepetIcerik sptKaydedenId.
     * @member {string} sptKaydedenId
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptKaydedenId = "";

    /**
     * sepetIcerik sptUId.
     * @member {string} sptUId
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptUId = "";

    /**
     * sepetIcerik sptUTip.
     * @member {number|null|undefined} sptUTip
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptUTip = null;

    /**
     * sepetIcerik sptMiktar.
     * @member {number} sptMiktar
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptMiktar = 0;

    /**
     * sepetIcerik sptTip.
     * @member {number} sptTip
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.sptTip = 0;

    /**
     * sepetIcerik cevapTip.
     * @member {number} cevapTip
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.cevapTip = 0;

    /**
     * sepetIcerik cevap.
     * @member {string} cevap
     * @memberof sepetIcerik
     * @instance
     */
    sepetIcerik.prototype.cevap = "";

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * sepetIcerik _sptUTip.
     * @member {"sptUTip"|undefined} _sptUTip
     * @memberof sepetIcerik
     * @instance
     */
    Object.defineProperty(sepetIcerik.prototype, "_sptUTip", {
        get: $util.oneOfGetter($oneOfFields = ["sptUTip"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new sepetIcerik instance using the specified properties.
     * @function create
     * @memberof sepetIcerik
     * @static
     * @param {IsepetIcerik=} [properties] Properties to set
     * @returns {sepetIcerik} sepetIcerik instance
     */
    sepetIcerik.create = function create(properties) {
        return new sepetIcerik(properties);
    };

    /**
     * Encodes the specified sepetIcerik message. Does not implicitly {@link sepetIcerik.verify|verify} messages.
     * @function encode
     * @memberof sepetIcerik
     * @static
     * @param {IsepetIcerik} message sepetIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    sepetIcerik.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sptId != null && Object.hasOwnProperty.call(message, "sptId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sptId);
        if (message.sptEid != null && Object.hasOwnProperty.call(message, "sptEid"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sptEid);
        if (message.sptOkulId != null && Object.hasOwnProperty.call(message, "sptOkulId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.sptOkulId);
        if (message.sptKaydedenId != null && Object.hasOwnProperty.call(message, "sptKaydedenId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.sptKaydedenId);
        if (message.sptUId != null && Object.hasOwnProperty.call(message, "sptUId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.sptUId);
        if (message.sptUTip != null && Object.hasOwnProperty.call(message, "sptUTip"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.sptUTip);
        if (message.sptMiktar != null && Object.hasOwnProperty.call(message, "sptMiktar"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.sptMiktar);
        if (message.sptTip != null && Object.hasOwnProperty.call(message, "sptTip"))
            writer.uint32(/* id 8, wireType 5 =*/69).float(message.sptTip);
        if (message.cevapTip != null && Object.hasOwnProperty.call(message, "cevapTip"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.cevapTip);
        if (message.cevap != null && Object.hasOwnProperty.call(message, "cevap"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.cevap);
        return writer;
    };

    /**
     * Encodes the specified sepetIcerik message, length delimited. Does not implicitly {@link sepetIcerik.verify|verify} messages.
     * @function encodeDelimited
     * @memberof sepetIcerik
     * @static
     * @param {IsepetIcerik} message sepetIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    sepetIcerik.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a sepetIcerik message from the specified reader or buffer.
     * @function decode
     * @memberof sepetIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {sepetIcerik} sepetIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    sepetIcerik.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sepetIcerik();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.sptId = reader.string();
                break;
            case 2:
                message.sptEid = reader.string();
                break;
            case 3:
                message.sptOkulId = reader.string();
                break;
            case 4:
                message.sptKaydedenId = reader.string();
                break;
            case 5:
                message.sptUId = reader.string();
                break;
            case 6:
                message.sptUTip = reader.int32();
                break;
            case 7:
                message.sptMiktar = reader.int32();
                break;
            case 8:
                message.sptTip = reader.float();
                break;
            case 9:
                message.cevapTip = reader.int32();
                break;
            case 10:
                message.cevap = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a sepetIcerik message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof sepetIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {sepetIcerik} sepetIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    sepetIcerik.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a sepetIcerik message.
     * @function verify
     * @memberof sepetIcerik
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    sepetIcerik.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (message.sptId != null && message.hasOwnProperty("sptId"))
            if (!$util.isString(message.sptId))
                return "sptId: string expected";
        if (message.sptEid != null && message.hasOwnProperty("sptEid"))
            if (!$util.isString(message.sptEid))
                return "sptEid: string expected";
        if (message.sptOkulId != null && message.hasOwnProperty("sptOkulId"))
            if (!$util.isString(message.sptOkulId))
                return "sptOkulId: string expected";
        if (message.sptKaydedenId != null && message.hasOwnProperty("sptKaydedenId"))
            if (!$util.isString(message.sptKaydedenId))
                return "sptKaydedenId: string expected";
        if (message.sptUId != null && message.hasOwnProperty("sptUId"))
            if (!$util.isString(message.sptUId))
                return "sptUId: string expected";
        if (message.sptUTip != null && message.hasOwnProperty("sptUTip")) {
            properties._sptUTip = 1;
            if (!$util.isInteger(message.sptUTip))
                return "sptUTip: integer expected";
        }
        if (message.sptMiktar != null && message.hasOwnProperty("sptMiktar"))
            if (!$util.isInteger(message.sptMiktar))
                return "sptMiktar: integer expected";
        if (message.sptTip != null && message.hasOwnProperty("sptTip"))
            if (typeof message.sptTip !== "number")
                return "sptTip: number expected";
        if (message.cevapTip != null && message.hasOwnProperty("cevapTip"))
            if (!$util.isInteger(message.cevapTip))
                return "cevapTip: integer expected";
        if (message.cevap != null && message.hasOwnProperty("cevap"))
            if (!$util.isString(message.cevap))
                return "cevap: string expected";
        return null;
    };

    /**
     * Creates a sepetIcerik message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof sepetIcerik
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {sepetIcerik} sepetIcerik
     */
    sepetIcerik.fromObject = function fromObject(object) {
        if (object instanceof $root.sepetIcerik)
            return object;
        var message = new $root.sepetIcerik();
        if (object.sptId != null)
            message.sptId = String(object.sptId);
        if (object.sptEid != null)
            message.sptEid = String(object.sptEid);
        if (object.sptOkulId != null)
            message.sptOkulId = String(object.sptOkulId);
        if (object.sptKaydedenId != null)
            message.sptKaydedenId = String(object.sptKaydedenId);
        if (object.sptUId != null)
            message.sptUId = String(object.sptUId);
        if (object.sptUTip != null)
            message.sptUTip = object.sptUTip | 0;
        if (object.sptMiktar != null)
            message.sptMiktar = object.sptMiktar | 0;
        if (object.sptTip != null)
            message.sptTip = Number(object.sptTip);
        if (object.cevapTip != null)
            message.cevapTip = object.cevapTip | 0;
        if (object.cevap != null)
            message.cevap = String(object.cevap);
        return message;
    };

    /**
     * Creates a plain object from a sepetIcerik message. Also converts values to other types if specified.
     * @function toObject
     * @memberof sepetIcerik
     * @static
     * @param {sepetIcerik} message sepetIcerik
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    sepetIcerik.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sptId = "";
            object.sptEid = "";
            object.sptOkulId = "";
            object.sptKaydedenId = "";
            object.sptUId = "";
            object.sptMiktar = 0;
            object.sptTip = 0;
            object.cevapTip = 0;
            object.cevap = "";
        }
        if (message.sptId != null && message.hasOwnProperty("sptId"))
            object.sptId = message.sptId;
        if (message.sptEid != null && message.hasOwnProperty("sptEid"))
            object.sptEid = message.sptEid;
        if (message.sptOkulId != null && message.hasOwnProperty("sptOkulId"))
            object.sptOkulId = message.sptOkulId;
        if (message.sptKaydedenId != null && message.hasOwnProperty("sptKaydedenId"))
            object.sptKaydedenId = message.sptKaydedenId;
        if (message.sptUId != null && message.hasOwnProperty("sptUId"))
            object.sptUId = message.sptUId;
        if (message.sptUTip != null && message.hasOwnProperty("sptUTip")) {
            object.sptUTip = message.sptUTip;
            if (options.oneofs)
                object._sptUTip = "sptUTip";
        }
        if (message.sptMiktar != null && message.hasOwnProperty("sptMiktar"))
            object.sptMiktar = message.sptMiktar;
        if (message.sptTip != null && message.hasOwnProperty("sptTip"))
            object.sptTip = options.json && !isFinite(message.sptTip) ? String(message.sptTip) : message.sptTip;
        if (message.cevapTip != null && message.hasOwnProperty("cevapTip"))
            object.cevapTip = message.cevapTip;
        if (message.cevap != null && message.hasOwnProperty("cevap"))
            object.cevap = message.cevap;
        return object;
    };

    /**
     * Converts this sepetIcerik to JSON.
     * @function toJSON
     * @memberof sepetIcerik
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    sepetIcerik.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return sepetIcerik;
})();

$root.sepet = (function() {

    /**
     * Properties of a sepet.
     * @exports Isepet
     * @interface Isepet
     * @property {number|null} [tip] sepet tip
     * @property {string|null} [sepetId] sepet sepetId
     * @property {string|null} [okulId] sepet okulId
     * @property {string|null} [kaydedenId] sepet kaydedenId
     * @property {string|null} [ogrenciId] sepet ogrenciId
     * @property {string|null} [urunId] sepet urunId
     * @property {number|null} [urunTip] sepet urunTip
     * @property {number|null} [miktar] sepet miktar
     * @property {number|null} [sepetTip] sepet sepetTip
     * @property {Array.<IsepetIcerik>|null} [sonuc] sepet sonuc
     */

    /**
     * Constructs a new sepet.
     * @exports sepet
     * @classdesc Represents a sepet.
     * @implements Isepet
     * @constructor
     * @param {Isepet=} [properties] Properties to set
     */
    function sepet(properties) {
        this.sonuc = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * sepet tip.
     * @member {number} tip
     * @memberof sepet
     * @instance
     */
    sepet.prototype.tip = 0;

    /**
     * sepet sepetId.
     * @member {string} sepetId
     * @memberof sepet
     * @instance
     */
    sepet.prototype.sepetId = "";

    /**
     * sepet okulId.
     * @member {string} okulId
     * @memberof sepet
     * @instance
     */
    sepet.prototype.okulId = "";

    /**
     * sepet kaydedenId.
     * @member {string} kaydedenId
     * @memberof sepet
     * @instance
     */
    sepet.prototype.kaydedenId = "";

    /**
     * sepet ogrenciId.
     * @member {string} ogrenciId
     * @memberof sepet
     * @instance
     */
    sepet.prototype.ogrenciId = "";

    /**
     * sepet urunId.
     * @member {string} urunId
     * @memberof sepet
     * @instance
     */
    sepet.prototype.urunId = "";

    /**
     * sepet urunTip.
     * @member {number|null|undefined} urunTip
     * @memberof sepet
     * @instance
     */
    sepet.prototype.urunTip = null;

    /**
     * sepet miktar.
     * @member {number} miktar
     * @memberof sepet
     * @instance
     */
    sepet.prototype.miktar = 0;

    /**
     * sepet sepetTip.
     * @member {number} sepetTip
     * @memberof sepet
     * @instance
     */
    sepet.prototype.sepetTip = 0;

    /**
     * sepet sonuc.
     * @member {Array.<IsepetIcerik>} sonuc
     * @memberof sepet
     * @instance
     */
    sepet.prototype.sonuc = $util.emptyArray;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * sepet _urunTip.
     * @member {"urunTip"|undefined} _urunTip
     * @memberof sepet
     * @instance
     */
    Object.defineProperty(sepet.prototype, "_urunTip", {
        get: $util.oneOfGetter($oneOfFields = ["urunTip"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new sepet instance using the specified properties.
     * @function create
     * @memberof sepet
     * @static
     * @param {Isepet=} [properties] Properties to set
     * @returns {sepet} sepet instance
     */
    sepet.create = function create(properties) {
        return new sepet(properties);
    };

    /**
     * Encodes the specified sepet message. Does not implicitly {@link sepet.verify|verify} messages.
     * @function encode
     * @memberof sepet
     * @static
     * @param {Isepet} message sepet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    sepet.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tip);
        if (message.sepetId != null && Object.hasOwnProperty.call(message, "sepetId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.sepetId);
        if (message.okulId != null && Object.hasOwnProperty.call(message, "okulId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.okulId);
        if (message.kaydedenId != null && Object.hasOwnProperty.call(message, "kaydedenId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.kaydedenId);
        if (message.ogrenciId != null && Object.hasOwnProperty.call(message, "ogrenciId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ogrenciId);
        if (message.urunId != null && Object.hasOwnProperty.call(message, "urunId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.urunId);
        if (message.urunTip != null && Object.hasOwnProperty.call(message, "urunTip"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.urunTip);
        if (message.miktar != null && Object.hasOwnProperty.call(message, "miktar"))
            writer.uint32(/* id 8, wireType 5 =*/69).float(message.miktar);
        if (message.sepetTip != null && Object.hasOwnProperty.call(message, "sepetTip"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.sepetTip);
        if (message.sonuc != null && message.sonuc.length)
            for (var i = 0; i < message.sonuc.length; ++i)
                $root.sepetIcerik.encode(message.sonuc[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified sepet message, length delimited. Does not implicitly {@link sepet.verify|verify} messages.
     * @function encodeDelimited
     * @memberof sepet
     * @static
     * @param {Isepet} message sepet message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    sepet.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a sepet message from the specified reader or buffer.
     * @function decode
     * @memberof sepet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {sepet} sepet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    sepet.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.sepet();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.tip = reader.int32();
                break;
            case 2:
                message.sepetId = reader.string();
                break;
            case 3:
                message.okulId = reader.string();
                break;
            case 4:
                message.kaydedenId = reader.string();
                break;
            case 5:
                message.ogrenciId = reader.string();
                break;
            case 6:
                message.urunId = reader.string();
                break;
            case 7:
                message.urunTip = reader.int32();
                break;
            case 8:
                message.miktar = reader.float();
                break;
            case 9:
                message.sepetTip = reader.int32();
                break;
            case 10:
                if (!(message.sonuc && message.sonuc.length))
                    message.sonuc = [];
                message.sonuc.push($root.sepetIcerik.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a sepet message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof sepet
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {sepet} sepet
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    sepet.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a sepet message.
     * @function verify
     * @memberof sepet
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    sepet.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        if (message.sepetId != null && message.hasOwnProperty("sepetId"))
            if (!$util.isString(message.sepetId))
                return "sepetId: string expected";
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            if (!$util.isString(message.okulId))
                return "okulId: string expected";
        if (message.kaydedenId != null && message.hasOwnProperty("kaydedenId"))
            if (!$util.isString(message.kaydedenId))
                return "kaydedenId: string expected";
        if (message.ogrenciId != null && message.hasOwnProperty("ogrenciId"))
            if (!$util.isString(message.ogrenciId))
                return "ogrenciId: string expected";
        if (message.urunId != null && message.hasOwnProperty("urunId"))
            if (!$util.isString(message.urunId))
                return "urunId: string expected";
        if (message.urunTip != null && message.hasOwnProperty("urunTip")) {
            properties._urunTip = 1;
            if (!$util.isInteger(message.urunTip))
                return "urunTip: integer expected";
        }
        if (message.miktar != null && message.hasOwnProperty("miktar"))
            if (typeof message.miktar !== "number")
                return "miktar: number expected";
        if (message.sepetTip != null && message.hasOwnProperty("sepetTip"))
            if (!$util.isInteger(message.sepetTip))
                return "sepetTip: integer expected";
        if (message.sonuc != null && message.hasOwnProperty("sonuc")) {
            if (!Array.isArray(message.sonuc))
                return "sonuc: array expected";
            for (var i = 0; i < message.sonuc.length; ++i) {
                var error = $root.sepetIcerik.verify(message.sonuc[i]);
                if (error)
                    return "sonuc." + error;
            }
        }
        return null;
    };

    /**
     * Creates a sepet message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof sepet
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {sepet} sepet
     */
    sepet.fromObject = function fromObject(object) {
        if (object instanceof $root.sepet)
            return object;
        var message = new $root.sepet();
        if (object.tip != null)
            message.tip = object.tip | 0;
        if (object.sepetId != null)
            message.sepetId = String(object.sepetId);
        if (object.okulId != null)
            message.okulId = String(object.okulId);
        if (object.kaydedenId != null)
            message.kaydedenId = String(object.kaydedenId);
        if (object.ogrenciId != null)
            message.ogrenciId = String(object.ogrenciId);
        if (object.urunId != null)
            message.urunId = String(object.urunId);
        if (object.urunTip != null)
            message.urunTip = object.urunTip | 0;
        if (object.miktar != null)
            message.miktar = Number(object.miktar);
        if (object.sepetTip != null)
            message.sepetTip = object.sepetTip | 0;
        if (object.sonuc) {
            if (!Array.isArray(object.sonuc))
                throw TypeError(".sepet.sonuc: array expected");
            message.sonuc = [];
            for (var i = 0; i < object.sonuc.length; ++i) {
                if (typeof object.sonuc[i] !== "object")
                    throw TypeError(".sepet.sonuc: object expected");
                message.sonuc[i] = $root.sepetIcerik.fromObject(object.sonuc[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a sepet message. Also converts values to other types if specified.
     * @function toObject
     * @memberof sepet
     * @static
     * @param {sepet} message sepet
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    sepet.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.sonuc = [];
        if (options.defaults) {
            object.tip = 0;
            object.sepetId = "";
            object.okulId = "";
            object.kaydedenId = "";
            object.ogrenciId = "";
            object.urunId = "";
            object.miktar = 0;
            object.sepetTip = 0;
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        if (message.sepetId != null && message.hasOwnProperty("sepetId"))
            object.sepetId = message.sepetId;
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            object.okulId = message.okulId;
        if (message.kaydedenId != null && message.hasOwnProperty("kaydedenId"))
            object.kaydedenId = message.kaydedenId;
        if (message.ogrenciId != null && message.hasOwnProperty("ogrenciId"))
            object.ogrenciId = message.ogrenciId;
        if (message.urunId != null && message.hasOwnProperty("urunId"))
            object.urunId = message.urunId;
        if (message.urunTip != null && message.hasOwnProperty("urunTip")) {
            object.urunTip = message.urunTip;
            if (options.oneofs)
                object._urunTip = "urunTip";
        }
        if (message.miktar != null && message.hasOwnProperty("miktar"))
            object.miktar = options.json && !isFinite(message.miktar) ? String(message.miktar) : message.miktar;
        if (message.sepetTip != null && message.hasOwnProperty("sepetTip"))
            object.sepetTip = message.sepetTip;
        if (message.sonuc && message.sonuc.length) {
            object.sonuc = [];
            for (var j = 0; j < message.sonuc.length; ++j)
                object.sonuc[j] = $root.sepetIcerik.toObject(message.sonuc[j], options);
        }
        return object;
    };

    /**
     * Converts this sepet to JSON.
     * @function toJSON
     * @memberof sepet
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    sepet.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return sepet;
})();

$root.grupIcerik = (function() {

    /**
     * Properties of a grupIcerik.
     * @exports IgrupIcerik
     * @interface IgrupIcerik
     * @property {string|null} [grId] grupIcerik grId
     * @property {string|null} [grAdi] grupIcerik grAdi
     * @property {number|null} [grTip] grupIcerik grTip
     * @property {string|null} [grOkulId] grupIcerik grOkulId
     * @property {string|null} [grKaydedenId] grupIcerik grKaydedenId
     */

    /**
     * Constructs a new grupIcerik.
     * @exports grupIcerik
     * @classdesc Represents a grupIcerik.
     * @implements IgrupIcerik
     * @constructor
     * @param {IgrupIcerik=} [properties] Properties to set
     */
    function grupIcerik(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * grupIcerik grId.
     * @member {string} grId
     * @memberof grupIcerik
     * @instance
     */
    grupIcerik.prototype.grId = "";

    /**
     * grupIcerik grAdi.
     * @member {string} grAdi
     * @memberof grupIcerik
     * @instance
     */
    grupIcerik.prototype.grAdi = "";

    /**
     * grupIcerik grTip.
     * @member {number} grTip
     * @memberof grupIcerik
     * @instance
     */
    grupIcerik.prototype.grTip = 0;

    /**
     * grupIcerik grOkulId.
     * @member {string} grOkulId
     * @memberof grupIcerik
     * @instance
     */
    grupIcerik.prototype.grOkulId = "";

    /**
     * grupIcerik grKaydedenId.
     * @member {string} grKaydedenId
     * @memberof grupIcerik
     * @instance
     */
    grupIcerik.prototype.grKaydedenId = "";

    /**
     * Creates a new grupIcerik instance using the specified properties.
     * @function create
     * @memberof grupIcerik
     * @static
     * @param {IgrupIcerik=} [properties] Properties to set
     * @returns {grupIcerik} grupIcerik instance
     */
    grupIcerik.create = function create(properties) {
        return new grupIcerik(properties);
    };

    /**
     * Encodes the specified grupIcerik message. Does not implicitly {@link grupIcerik.verify|verify} messages.
     * @function encode
     * @memberof grupIcerik
     * @static
     * @param {IgrupIcerik} message grupIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    grupIcerik.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.grId != null && Object.hasOwnProperty.call(message, "grId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.grId);
        if (message.grAdi != null && Object.hasOwnProperty.call(message, "grAdi"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.grAdi);
        if (message.grTip != null && Object.hasOwnProperty.call(message, "grTip"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.grTip);
        if (message.grOkulId != null && Object.hasOwnProperty.call(message, "grOkulId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.grOkulId);
        if (message.grKaydedenId != null && Object.hasOwnProperty.call(message, "grKaydedenId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.grKaydedenId);
        return writer;
    };

    /**
     * Encodes the specified grupIcerik message, length delimited. Does not implicitly {@link grupIcerik.verify|verify} messages.
     * @function encodeDelimited
     * @memberof grupIcerik
     * @static
     * @param {IgrupIcerik} message grupIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    grupIcerik.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a grupIcerik message from the specified reader or buffer.
     * @function decode
     * @memberof grupIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {grupIcerik} grupIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    grupIcerik.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.grupIcerik();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.grId = reader.string();
                break;
            case 2:
                message.grAdi = reader.string();
                break;
            case 3:
                message.grTip = reader.int32();
                break;
            case 4:
                message.grOkulId = reader.string();
                break;
            case 5:
                message.grKaydedenId = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a grupIcerik message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof grupIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {grupIcerik} grupIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    grupIcerik.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a grupIcerik message.
     * @function verify
     * @memberof grupIcerik
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    grupIcerik.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.grId != null && message.hasOwnProperty("grId"))
            if (!$util.isString(message.grId))
                return "grId: string expected";
        if (message.grAdi != null && message.hasOwnProperty("grAdi"))
            if (!$util.isString(message.grAdi))
                return "grAdi: string expected";
        if (message.grTip != null && message.hasOwnProperty("grTip"))
            if (!$util.isInteger(message.grTip))
                return "grTip: integer expected";
        if (message.grOkulId != null && message.hasOwnProperty("grOkulId"))
            if (!$util.isString(message.grOkulId))
                return "grOkulId: string expected";
        if (message.grKaydedenId != null && message.hasOwnProperty("grKaydedenId"))
            if (!$util.isString(message.grKaydedenId))
                return "grKaydedenId: string expected";
        return null;
    };

    /**
     * Creates a grupIcerik message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof grupIcerik
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {grupIcerik} grupIcerik
     */
    grupIcerik.fromObject = function fromObject(object) {
        if (object instanceof $root.grupIcerik)
            return object;
        var message = new $root.grupIcerik();
        if (object.grId != null)
            message.grId = String(object.grId);
        if (object.grAdi != null)
            message.grAdi = String(object.grAdi);
        if (object.grTip != null)
            message.grTip = object.grTip | 0;
        if (object.grOkulId != null)
            message.grOkulId = String(object.grOkulId);
        if (object.grKaydedenId != null)
            message.grKaydedenId = String(object.grKaydedenId);
        return message;
    };

    /**
     * Creates a plain object from a grupIcerik message. Also converts values to other types if specified.
     * @function toObject
     * @memberof grupIcerik
     * @static
     * @param {grupIcerik} message grupIcerik
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    grupIcerik.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.grId = "";
            object.grAdi = "";
            object.grTip = 0;
            object.grOkulId = "";
            object.grKaydedenId = "";
        }
        if (message.grId != null && message.hasOwnProperty("grId"))
            object.grId = message.grId;
        if (message.grAdi != null && message.hasOwnProperty("grAdi"))
            object.grAdi = message.grAdi;
        if (message.grTip != null && message.hasOwnProperty("grTip"))
            object.grTip = message.grTip;
        if (message.grOkulId != null && message.hasOwnProperty("grOkulId"))
            object.grOkulId = message.grOkulId;
        if (message.grKaydedenId != null && message.hasOwnProperty("grKaydedenId"))
            object.grKaydedenId = message.grKaydedenId;
        return object;
    };

    /**
     * Converts this grupIcerik to JSON.
     * @function toJSON
     * @memberof grupIcerik
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    grupIcerik.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return grupIcerik;
})();

$root.grup = (function() {

    /**
     * Properties of a grup.
     * @exports Igrup
     * @interface Igrup
     * @property {number|null} [tip] grup tip
     * @property {string|null} [okulId] grup okulId
     * @property {Array.<IgrupIcerik>|null} [sonuc] grup sonuc
     */

    /**
     * Constructs a new grup.
     * @exports grup
     * @classdesc Represents a grup.
     * @implements Igrup
     * @constructor
     * @param {Igrup=} [properties] Properties to set
     */
    function grup(properties) {
        this.sonuc = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * grup tip.
     * @member {number} tip
     * @memberof grup
     * @instance
     */
    grup.prototype.tip = 0;

    /**
     * grup okulId.
     * @member {string} okulId
     * @memberof grup
     * @instance
     */
    grup.prototype.okulId = "";

    /**
     * grup sonuc.
     * @member {Array.<IgrupIcerik>} sonuc
     * @memberof grup
     * @instance
     */
    grup.prototype.sonuc = $util.emptyArray;

    /**
     * Creates a new grup instance using the specified properties.
     * @function create
     * @memberof grup
     * @static
     * @param {Igrup=} [properties] Properties to set
     * @returns {grup} grup instance
     */
    grup.create = function create(properties) {
        return new grup(properties);
    };

    /**
     * Encodes the specified grup message. Does not implicitly {@link grup.verify|verify} messages.
     * @function encode
     * @memberof grup
     * @static
     * @param {Igrup} message grup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    grup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.tip);
        if (message.okulId != null && Object.hasOwnProperty.call(message, "okulId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.okulId);
        if (message.sonuc != null && message.sonuc.length)
            for (var i = 0; i < message.sonuc.length; ++i)
                $root.grupIcerik.encode(message.sonuc[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified grup message, length delimited. Does not implicitly {@link grup.verify|verify} messages.
     * @function encodeDelimited
     * @memberof grup
     * @static
     * @param {Igrup} message grup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    grup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a grup message from the specified reader or buffer.
     * @function decode
     * @memberof grup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {grup} grup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    grup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.grup();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.tip = reader.int32();
                break;
            case 2:
                message.okulId = reader.string();
                break;
            case 3:
                if (!(message.sonuc && message.sonuc.length))
                    message.sonuc = [];
                message.sonuc.push($root.grupIcerik.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a grup message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof grup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {grup} grup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    grup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a grup message.
     * @function verify
     * @memberof grup
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    grup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            if (!$util.isString(message.okulId))
                return "okulId: string expected";
        if (message.sonuc != null && message.hasOwnProperty("sonuc")) {
            if (!Array.isArray(message.sonuc))
                return "sonuc: array expected";
            for (var i = 0; i < message.sonuc.length; ++i) {
                var error = $root.grupIcerik.verify(message.sonuc[i]);
                if (error)
                    return "sonuc." + error;
            }
        }
        return null;
    };

    /**
     * Creates a grup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof grup
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {grup} grup
     */
    grup.fromObject = function fromObject(object) {
        if (object instanceof $root.grup)
            return object;
        var message = new $root.grup();
        if (object.tip != null)
            message.tip = object.tip | 0;
        if (object.okulId != null)
            message.okulId = String(object.okulId);
        if (object.sonuc) {
            if (!Array.isArray(object.sonuc))
                throw TypeError(".grup.sonuc: array expected");
            message.sonuc = [];
            for (var i = 0; i < object.sonuc.length; ++i) {
                if (typeof object.sonuc[i] !== "object")
                    throw TypeError(".grup.sonuc: object expected");
                message.sonuc[i] = $root.grupIcerik.fromObject(object.sonuc[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a grup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof grup
     * @static
     * @param {grup} message grup
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    grup.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.sonuc = [];
        if (options.defaults) {
            object.tip = 0;
            object.okulId = "";
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        if (message.okulId != null && message.hasOwnProperty("okulId"))
            object.okulId = message.okulId;
        if (message.sonuc && message.sonuc.length) {
            object.sonuc = [];
            for (var j = 0; j < message.sonuc.length; ++j)
                object.sonuc[j] = $root.grupIcerik.toObject(message.sonuc[j], options);
        }
        return object;
    };

    /**
     * Converts this grup to JSON.
     * @function toJSON
     * @memberof grup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    grup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return grup;
})();

$root.pdksGrupIcerik = (function() {

    /**
     * Properties of a pdksGrupIcerik.
     * @exports IpdksGrupIcerik
     * @interface IpdksGrupIcerik
     * @property {number|null} [pdksId] pdksGrupIcerik pdksId
     * @property {string|null} [pdksCToken] pdksGrupIcerik pdksCToken
     * @property {string|null} [pdksGToken] pdksGrupIcerik pdksGToken
     * @property {number|null} [pdksSilincekMi] pdksGrupIcerik pdksSilincekMi
     * @property {string|null} [pdksZaman1] pdksGrupIcerik pdksZaman1
     * @property {string|null} [pdksZaman2] pdksGrupIcerik pdksZaman2
     * @property {string|null} [pdksZaman3] pdksGrupIcerik pdksZaman3
     * @property {string|null} [pdksZaman4] pdksGrupIcerik pdksZaman4
     * @property {string|null} [pdksZaman5] pdksGrupIcerik pdksZaman5
     */

    /**
     * Constructs a new pdksGrupIcerik.
     * @exports pdksGrupIcerik
     * @classdesc Represents a pdksGrupIcerik.
     * @implements IpdksGrupIcerik
     * @constructor
     * @param {IpdksGrupIcerik=} [properties] Properties to set
     */
    function pdksGrupIcerik(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * pdksGrupIcerik pdksId.
     * @member {number} pdksId
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksId = 0;

    /**
     * pdksGrupIcerik pdksCToken.
     * @member {string} pdksCToken
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksCToken = "";

    /**
     * pdksGrupIcerik pdksGToken.
     * @member {string} pdksGToken
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksGToken = "";

    /**
     * pdksGrupIcerik pdksSilincekMi.
     * @member {number|null|undefined} pdksSilincekMi
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksSilincekMi = null;

    /**
     * pdksGrupIcerik pdksZaman1.
     * @member {string} pdksZaman1
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksZaman1 = "";

    /**
     * pdksGrupIcerik pdksZaman2.
     * @member {string} pdksZaman2
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksZaman2 = "";

    /**
     * pdksGrupIcerik pdksZaman3.
     * @member {string} pdksZaman3
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksZaman3 = "";

    /**
     * pdksGrupIcerik pdksZaman4.
     * @member {string} pdksZaman4
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksZaman4 = "";

    /**
     * pdksGrupIcerik pdksZaman5.
     * @member {string} pdksZaman5
     * @memberof pdksGrupIcerik
     * @instance
     */
    pdksGrupIcerik.prototype.pdksZaman5 = "";

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * pdksGrupIcerik _pdksSilincekMi.
     * @member {"pdksSilincekMi"|undefined} _pdksSilincekMi
     * @memberof pdksGrupIcerik
     * @instance
     */
    Object.defineProperty(pdksGrupIcerik.prototype, "_pdksSilincekMi", {
        get: $util.oneOfGetter($oneOfFields = ["pdksSilincekMi"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new pdksGrupIcerik instance using the specified properties.
     * @function create
     * @memberof pdksGrupIcerik
     * @static
     * @param {IpdksGrupIcerik=} [properties] Properties to set
     * @returns {pdksGrupIcerik} pdksGrupIcerik instance
     */
    pdksGrupIcerik.create = function create(properties) {
        return new pdksGrupIcerik(properties);
    };

    /**
     * Encodes the specified pdksGrupIcerik message. Does not implicitly {@link pdksGrupIcerik.verify|verify} messages.
     * @function encode
     * @memberof pdksGrupIcerik
     * @static
     * @param {IpdksGrupIcerik} message pdksGrupIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    pdksGrupIcerik.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.pdksId != null && Object.hasOwnProperty.call(message, "pdksId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pdksId);
        if (message.pdksCToken != null && Object.hasOwnProperty.call(message, "pdksCToken"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.pdksCToken);
        if (message.pdksGToken != null && Object.hasOwnProperty.call(message, "pdksGToken"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.pdksGToken);
        if (message.pdksSilincekMi != null && Object.hasOwnProperty.call(message, "pdksSilincekMi"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.pdksSilincekMi);
        if (message.pdksZaman1 != null && Object.hasOwnProperty.call(message, "pdksZaman1"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pdksZaman1);
        if (message.pdksZaman2 != null && Object.hasOwnProperty.call(message, "pdksZaman2"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.pdksZaman2);
        if (message.pdksZaman3 != null && Object.hasOwnProperty.call(message, "pdksZaman3"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.pdksZaman3);
        if (message.pdksZaman4 != null && Object.hasOwnProperty.call(message, "pdksZaman4"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.pdksZaman4);
        if (message.pdksZaman5 != null && Object.hasOwnProperty.call(message, "pdksZaman5"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.pdksZaman5);
        return writer;
    };

    /**
     * Encodes the specified pdksGrupIcerik message, length delimited. Does not implicitly {@link pdksGrupIcerik.verify|verify} messages.
     * @function encodeDelimited
     * @memberof pdksGrupIcerik
     * @static
     * @param {IpdksGrupIcerik} message pdksGrupIcerik message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    pdksGrupIcerik.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a pdksGrupIcerik message from the specified reader or buffer.
     * @function decode
     * @memberof pdksGrupIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {pdksGrupIcerik} pdksGrupIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    pdksGrupIcerik.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pdksGrupIcerik();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.pdksId = reader.int32();
                break;
            case 2:
                message.pdksCToken = reader.string();
                break;
            case 3:
                message.pdksGToken = reader.string();
                break;
            case 4:
                message.pdksSilincekMi = reader.int32();
                break;
            case 5:
                message.pdksZaman1 = reader.string();
                break;
            case 6:
                message.pdksZaman2 = reader.string();
                break;
            case 7:
                message.pdksZaman3 = reader.string();
                break;
            case 8:
                message.pdksZaman4 = reader.string();
                break;
            case 9:
                message.pdksZaman5 = reader.string();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a pdksGrupIcerik message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof pdksGrupIcerik
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {pdksGrupIcerik} pdksGrupIcerik
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    pdksGrupIcerik.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a pdksGrupIcerik message.
     * @function verify
     * @memberof pdksGrupIcerik
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    pdksGrupIcerik.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (message.pdksId != null && message.hasOwnProperty("pdksId"))
            if (!$util.isInteger(message.pdksId))
                return "pdksId: integer expected";
        if (message.pdksCToken != null && message.hasOwnProperty("pdksCToken"))
            if (!$util.isString(message.pdksCToken))
                return "pdksCToken: string expected";
        if (message.pdksGToken != null && message.hasOwnProperty("pdksGToken"))
            if (!$util.isString(message.pdksGToken))
                return "pdksGToken: string expected";
        if (message.pdksSilincekMi != null && message.hasOwnProperty("pdksSilincekMi")) {
            properties._pdksSilincekMi = 1;
            if (!$util.isInteger(message.pdksSilincekMi))
                return "pdksSilincekMi: integer expected";
        }
        if (message.pdksZaman1 != null && message.hasOwnProperty("pdksZaman1"))
            if (!$util.isString(message.pdksZaman1))
                return "pdksZaman1: string expected";
        if (message.pdksZaman2 != null && message.hasOwnProperty("pdksZaman2"))
            if (!$util.isString(message.pdksZaman2))
                return "pdksZaman2: string expected";
        if (message.pdksZaman3 != null && message.hasOwnProperty("pdksZaman3"))
            if (!$util.isString(message.pdksZaman3))
                return "pdksZaman3: string expected";
        if (message.pdksZaman4 != null && message.hasOwnProperty("pdksZaman4"))
            if (!$util.isString(message.pdksZaman4))
                return "pdksZaman4: string expected";
        if (message.pdksZaman5 != null && message.hasOwnProperty("pdksZaman5"))
            if (!$util.isString(message.pdksZaman5))
                return "pdksZaman5: string expected";
        return null;
    };

    /**
     * Creates a pdksGrupIcerik message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof pdksGrupIcerik
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {pdksGrupIcerik} pdksGrupIcerik
     */
    pdksGrupIcerik.fromObject = function fromObject(object) {
        if (object instanceof $root.pdksGrupIcerik)
            return object;
        var message = new $root.pdksGrupIcerik();
        if (object.pdksId != null)
            message.pdksId = object.pdksId | 0;
        if (object.pdksCToken != null)
            message.pdksCToken = String(object.pdksCToken);
        if (object.pdksGToken != null)
            message.pdksGToken = String(object.pdksGToken);
        if (object.pdksSilincekMi != null)
            message.pdksSilincekMi = object.pdksSilincekMi | 0;
        if (object.pdksZaman1 != null)
            message.pdksZaman1 = String(object.pdksZaman1);
        if (object.pdksZaman2 != null)
            message.pdksZaman2 = String(object.pdksZaman2);
        if (object.pdksZaman3 != null)
            message.pdksZaman3 = String(object.pdksZaman3);
        if (object.pdksZaman4 != null)
            message.pdksZaman4 = String(object.pdksZaman4);
        if (object.pdksZaman5 != null)
            message.pdksZaman5 = String(object.pdksZaman5);
        return message;
    };

    /**
     * Creates a plain object from a pdksGrupIcerik message. Also converts values to other types if specified.
     * @function toObject
     * @memberof pdksGrupIcerik
     * @static
     * @param {pdksGrupIcerik} message pdksGrupIcerik
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    pdksGrupIcerik.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.pdksId = 0;
            object.pdksCToken = "";
            object.pdksGToken = "";
            object.pdksZaman1 = "";
            object.pdksZaman2 = "";
            object.pdksZaman3 = "";
            object.pdksZaman4 = "";
            object.pdksZaman5 = "";
        }
        if (message.pdksId != null && message.hasOwnProperty("pdksId"))
            object.pdksId = message.pdksId;
        if (message.pdksCToken != null && message.hasOwnProperty("pdksCToken"))
            object.pdksCToken = message.pdksCToken;
        if (message.pdksGToken != null && message.hasOwnProperty("pdksGToken"))
            object.pdksGToken = message.pdksGToken;
        if (message.pdksSilincekMi != null && message.hasOwnProperty("pdksSilincekMi")) {
            object.pdksSilincekMi = message.pdksSilincekMi;
            if (options.oneofs)
                object._pdksSilincekMi = "pdksSilincekMi";
        }
        if (message.pdksZaman1 != null && message.hasOwnProperty("pdksZaman1"))
            object.pdksZaman1 = message.pdksZaman1;
        if (message.pdksZaman2 != null && message.hasOwnProperty("pdksZaman2"))
            object.pdksZaman2 = message.pdksZaman2;
        if (message.pdksZaman3 != null && message.hasOwnProperty("pdksZaman3"))
            object.pdksZaman3 = message.pdksZaman3;
        if (message.pdksZaman4 != null && message.hasOwnProperty("pdksZaman4"))
            object.pdksZaman4 = message.pdksZaman4;
        if (message.pdksZaman5 != null && message.hasOwnProperty("pdksZaman5"))
            object.pdksZaman5 = message.pdksZaman5;
        return object;
    };

    /**
     * Converts this pdksGrupIcerik to JSON.
     * @function toJSON
     * @memberof pdksGrupIcerik
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    pdksGrupIcerik.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return pdksGrupIcerik;
})();

$root.pdksGrup = (function() {

    /**
     * Properties of a pdksGrup.
     * @exports IpdksGrup
     * @interface IpdksGrup
     * @property {number|null} [id] pdksGrup id
     * @property {number|null} [tip] pdksGrup tip
     * @property {string|null} [cToken] pdksGrup cToken
     * @property {string|null} [gToken] pdksGrup gToken
     * @property {number|null} [silincekMi] pdksGrup silincekMi
     * @property {string|null} [zaman1] pdksGrup zaman1
     * @property {string|null} [zaman2] pdksGrup zaman2
     * @property {string|null} [zaman3] pdksGrup zaman3
     * @property {string|null} [zaman4] pdksGrup zaman4
     * @property {string|null} [zaman5] pdksGrup zaman5
     * @property {Array.<IpdksGrupIcerik>|null} [sonuc] pdksGrup sonuc
     */

    /**
     * Constructs a new pdksGrup.
     * @exports pdksGrup
     * @classdesc Represents a pdksGrup.
     * @implements IpdksGrup
     * @constructor
     * @param {IpdksGrup=} [properties] Properties to set
     */
    function pdksGrup(properties) {
        this.sonuc = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * pdksGrup id.
     * @member {number|null|undefined} id
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.id = null;

    /**
     * pdksGrup tip.
     * @member {number} tip
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.tip = 0;

    /**
     * pdksGrup cToken.
     * @member {string} cToken
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.cToken = "";

    /**
     * pdksGrup gToken.
     * @member {string} gToken
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.gToken = "";

    /**
     * pdksGrup silincekMi.
     * @member {number|null|undefined} silincekMi
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.silincekMi = null;

    /**
     * pdksGrup zaman1.
     * @member {string} zaman1
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.zaman1 = "";

    /**
     * pdksGrup zaman2.
     * @member {string} zaman2
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.zaman2 = "";

    /**
     * pdksGrup zaman3.
     * @member {string} zaman3
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.zaman3 = "";

    /**
     * pdksGrup zaman4.
     * @member {string} zaman4
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.zaman4 = "";

    /**
     * pdksGrup zaman5.
     * @member {string} zaman5
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.zaman5 = "";

    /**
     * pdksGrup sonuc.
     * @member {Array.<IpdksGrupIcerik>} sonuc
     * @memberof pdksGrup
     * @instance
     */
    pdksGrup.prototype.sonuc = $util.emptyArray;

    // OneOf field names bound to virtual getters and setters
    var $oneOfFields;

    /**
     * pdksGrup _id.
     * @member {"id"|undefined} _id
     * @memberof pdksGrup
     * @instance
     */
    Object.defineProperty(pdksGrup.prototype, "_id", {
        get: $util.oneOfGetter($oneOfFields = ["id"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * pdksGrup _silincekMi.
     * @member {"silincekMi"|undefined} _silincekMi
     * @memberof pdksGrup
     * @instance
     */
    Object.defineProperty(pdksGrup.prototype, "_silincekMi", {
        get: $util.oneOfGetter($oneOfFields = ["silincekMi"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new pdksGrup instance using the specified properties.
     * @function create
     * @memberof pdksGrup
     * @static
     * @param {IpdksGrup=} [properties] Properties to set
     * @returns {pdksGrup} pdksGrup instance
     */
    pdksGrup.create = function create(properties) {
        return new pdksGrup(properties);
    };

    /**
     * Encodes the specified pdksGrup message. Does not implicitly {@link pdksGrup.verify|verify} messages.
     * @function encode
     * @memberof pdksGrup
     * @static
     * @param {IpdksGrup} message pdksGrup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    pdksGrup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.id);
        if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.tip);
        if (message.cToken != null && Object.hasOwnProperty.call(message, "cToken"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.cToken);
        if (message.gToken != null && Object.hasOwnProperty.call(message, "gToken"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.gToken);
        if (message.silincekMi != null && Object.hasOwnProperty.call(message, "silincekMi"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.silincekMi);
        if (message.zaman1 != null && Object.hasOwnProperty.call(message, "zaman1"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.zaman1);
        if (message.zaman2 != null && Object.hasOwnProperty.call(message, "zaman2"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.zaman2);
        if (message.zaman3 != null && Object.hasOwnProperty.call(message, "zaman3"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.zaman3);
        if (message.zaman4 != null && Object.hasOwnProperty.call(message, "zaman4"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.zaman4);
        if (message.zaman5 != null && Object.hasOwnProperty.call(message, "zaman5"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.zaman5);
        if (message.sonuc != null && message.sonuc.length)
            for (var i = 0; i < message.sonuc.length; ++i)
                $root.pdksGrupIcerik.encode(message.sonuc[i], writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified pdksGrup message, length delimited. Does not implicitly {@link pdksGrup.verify|verify} messages.
     * @function encodeDelimited
     * @memberof pdksGrup
     * @static
     * @param {IpdksGrup} message pdksGrup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    pdksGrup.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a pdksGrup message from the specified reader or buffer.
     * @function decode
     * @memberof pdksGrup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {pdksGrup} pdksGrup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    pdksGrup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.pdksGrup();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.id = reader.int32();
                break;
            case 2:
                message.tip = reader.int32();
                break;
            case 3:
                message.cToken = reader.string();
                break;
            case 4:
                message.gToken = reader.string();
                break;
            case 5:
                message.silincekMi = reader.int32();
                break;
            case 6:
                message.zaman1 = reader.string();
                break;
            case 7:
                message.zaman2 = reader.string();
                break;
            case 8:
                message.zaman3 = reader.string();
                break;
            case 9:
                message.zaman4 = reader.string();
                break;
            case 10:
                message.zaman5 = reader.string();
                break;
            case 11:
                if (!(message.sonuc && message.sonuc.length))
                    message.sonuc = [];
                message.sonuc.push($root.pdksGrupIcerik.decode(reader, reader.uint32()));
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a pdksGrup message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof pdksGrup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {pdksGrup} pdksGrup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    pdksGrup.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a pdksGrup message.
     * @function verify
     * @memberof pdksGrup
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    pdksGrup.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        var properties = {};
        if (message.id != null && message.hasOwnProperty("id")) {
            properties._id = 1;
            if (!$util.isInteger(message.id))
                return "id: integer expected";
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            if (!$util.isInteger(message.tip))
                return "tip: integer expected";
        if (message.cToken != null && message.hasOwnProperty("cToken"))
            if (!$util.isString(message.cToken))
                return "cToken: string expected";
        if (message.gToken != null && message.hasOwnProperty("gToken"))
            if (!$util.isString(message.gToken))
                return "gToken: string expected";
        if (message.silincekMi != null && message.hasOwnProperty("silincekMi")) {
            properties._silincekMi = 1;
            if (!$util.isInteger(message.silincekMi))
                return "silincekMi: integer expected";
        }
        if (message.zaman1 != null && message.hasOwnProperty("zaman1"))
            if (!$util.isString(message.zaman1))
                return "zaman1: string expected";
        if (message.zaman2 != null && message.hasOwnProperty("zaman2"))
            if (!$util.isString(message.zaman2))
                return "zaman2: string expected";
        if (message.zaman3 != null && message.hasOwnProperty("zaman3"))
            if (!$util.isString(message.zaman3))
                return "zaman3: string expected";
        if (message.zaman4 != null && message.hasOwnProperty("zaman4"))
            if (!$util.isString(message.zaman4))
                return "zaman4: string expected";
        if (message.zaman5 != null && message.hasOwnProperty("zaman5"))
            if (!$util.isString(message.zaman5))
                return "zaman5: string expected";
        if (message.sonuc != null && message.hasOwnProperty("sonuc")) {
            if (!Array.isArray(message.sonuc))
                return "sonuc: array expected";
            for (var i = 0; i < message.sonuc.length; ++i) {
                var error = $root.pdksGrupIcerik.verify(message.sonuc[i]);
                if (error)
                    return "sonuc." + error;
            }
        }
        return null;
    };

    /**
     * Creates a pdksGrup message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof pdksGrup
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {pdksGrup} pdksGrup
     */
    pdksGrup.fromObject = function fromObject(object) {
        if (object instanceof $root.pdksGrup)
            return object;
        var message = new $root.pdksGrup();
        if (object.id != null)
            message.id = object.id | 0;
        if (object.tip != null)
            message.tip = object.tip | 0;
        if (object.cToken != null)
            message.cToken = String(object.cToken);
        if (object.gToken != null)
            message.gToken = String(object.gToken);
        if (object.silincekMi != null)
            message.silincekMi = object.silincekMi | 0;
        if (object.zaman1 != null)
            message.zaman1 = String(object.zaman1);
        if (object.zaman2 != null)
            message.zaman2 = String(object.zaman2);
        if (object.zaman3 != null)
            message.zaman3 = String(object.zaman3);
        if (object.zaman4 != null)
            message.zaman4 = String(object.zaman4);
        if (object.zaman5 != null)
            message.zaman5 = String(object.zaman5);
        if (object.sonuc) {
            if (!Array.isArray(object.sonuc))
                throw TypeError(".pdksGrup.sonuc: array expected");
            message.sonuc = [];
            for (var i = 0; i < object.sonuc.length; ++i) {
                if (typeof object.sonuc[i] !== "object")
                    throw TypeError(".pdksGrup.sonuc: object expected");
                message.sonuc[i] = $root.pdksGrupIcerik.fromObject(object.sonuc[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a pdksGrup message. Also converts values to other types if specified.
     * @function toObject
     * @memberof pdksGrup
     * @static
     * @param {pdksGrup} message pdksGrup
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    pdksGrup.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.sonuc = [];
        if (options.defaults) {
            object.tip = 0;
            object.cToken = "";
            object.gToken = "";
            object.zaman1 = "";
            object.zaman2 = "";
            object.zaman3 = "";
            object.zaman4 = "";
            object.zaman5 = "";
        }
        if (message.id != null && message.hasOwnProperty("id")) {
            object.id = message.id;
            if (options.oneofs)
                object._id = "id";
        }
        if (message.tip != null && message.hasOwnProperty("tip"))
            object.tip = message.tip;
        if (message.cToken != null && message.hasOwnProperty("cToken"))
            object.cToken = message.cToken;
        if (message.gToken != null && message.hasOwnProperty("gToken"))
            object.gToken = message.gToken;
        if (message.silincekMi != null && message.hasOwnProperty("silincekMi")) {
            object.silincekMi = message.silincekMi;
            if (options.oneofs)
                object._silincekMi = "silincekMi";
        }
        if (message.zaman1 != null && message.hasOwnProperty("zaman1"))
            object.zaman1 = message.zaman1;
        if (message.zaman2 != null && message.hasOwnProperty("zaman2"))
            object.zaman2 = message.zaman2;
        if (message.zaman3 != null && message.hasOwnProperty("zaman3"))
            object.zaman3 = message.zaman3;
        if (message.zaman4 != null && message.hasOwnProperty("zaman4"))
            object.zaman4 = message.zaman4;
        if (message.zaman5 != null && message.hasOwnProperty("zaman5"))
            object.zaman5 = message.zaman5;
        if (message.sonuc && message.sonuc.length) {
            object.sonuc = [];
            for (var j = 0; j < message.sonuc.length; ++j)
                object.sonuc[j] = $root.pdksGrupIcerik.toObject(message.sonuc[j], options);
        }
        return object;
    };

    /**
     * Converts this pdksGrup to JSON.
     * @function toJSON
     * @memberof pdksGrup
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    pdksGrup.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return pdksGrup;
})();

module.exports = $root;
