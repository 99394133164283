export default {
  namespaced: true,
  state: {
    ogrenciVeKartlar: [],
    stoklar: [],
    gruplar: [],
    seciliOgrenci: {},
    ytUrl: 'https://yuztanima.kantinim.com.tr/',
  },
  getters: {},
  mutations: {
    UPDATE_KARTLAR(state, payload) {
      state.ogrenciVeKartlar = payload
    },
    UPDATE_STOKLAR(state, payload) {
      state.stoklar = payload
    },
    ADD_STOK(state, payload) {
      state.stoklar.push(payload)
    },
    UPDATE_GRUPLAR(state, payload) {
      state.gruplar = payload
    },
    ADD_GRUP(state, payload) {
      state.gruplar.push(payload)
    },
    CHANGE_SECILI(state, payload) {
      state.seciliOgrenci = payload
    },
  },
  actions: {},
}
