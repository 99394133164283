import Vue from 'vue'
import VueRouter from 'vue-router'

// eslint-disable-next-line import/no-cycle

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/app',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/yuztanima/yuzTanima.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/error/Error404.vue'),
    },
    {
      path: '*',
      redirect: '404',
    },
  ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
