/* eslint-disable */
import Message from './constants'

const CryptoJS = require('crypto-js')

const key = '26f140529a54e1050624ad0bdbe6626c'

function CryptJsWordArrayToUint8Array(wordArray) {
  const l = wordArray.sigBytes
  const { words } = wordArray
  const result = new Uint8Array(l)
  let i = 0; let
    j = 0
  while (true) {
    // here i is a multiple of 4
    if (i === l) break
    const w = words[j++]
    result[i++] = (w & 0xff000000) >>> 24
    if (i === l) break
    result[i++] = (w & 0x00ff0000) >>> 16
    if (i === l) break
    result[i++] = (w & 0x0000ff00) >>> 8
    if (i === l) break
    result[i++] = (w & 0x000000ff)
  }
  return result
}

export default function (data,url) {
  const bytes = CryptoJS.AES.decrypt(data, key)
  const msg = Message[url].decode(CryptJsWordArrayToUint8Array(bytes),CryptJsWordArrayToUint8Array(bytes).length - 1)
  return Message[url].toObject(msg)
}
